
import React from "react";

import "./accordion.scss";

const Accordion = (props) => {

	const {
		content
	} = props;

	function toggle (e) {
	}

	function sanitize(item) {

		return {
			__html: item.answer
		};
		
	}

	return (

		<div className="accordion">
			
			{content.map((item, index) => {

				return (
		
					<div className="accordion__item"
						key={index}>

						<input type="checkbox"
							id={`accordion-item-${index}`} />
						
						<label className="accordion__label"
							name={item.question}
							acceptedanswer={item.answer}
							htmlFor={`accordion-item-${index}`}
							onClick={(e) => toggle(e)} >

							{item.title}

						</label>

						<div className="accordion__plus-minus">

							<div className="accordion__plus-minus-horizontal"></div>
							<div className="accordion__plus-minus-vertical"></div>

						</div>

						<div className="accordion__answer"
							text={item.answer}>
						
								<div dangerouslySetInnerHTML={ sanitize(item) }
									className="accordion__content">
								</div>

						</div>

					</div>

				);
									
			})}

		</div>
        
	);
};

export default Accordion;
