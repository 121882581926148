
import React from "react";

import Intro from '../../atoms/intro/intro';

import Accordion from '../../molecules/accordion/accordion';

import "./faqs.scss";

const Faqs= (props) => {

	const {
		accordion,
		title,
		description
	} = props;

	return (

		<div className="content faqs">

			<Intro
				title={title}
				description={description}>
			</Intro>

			<Accordion 
				content={accordion}>
			</Accordion>

		</div>
        
	);
};

export default Faqs;
