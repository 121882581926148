
const faqsData= {

	title: "FAQs",
	description: [
		"Find answers to commonly asked questions about the Standard Bank Tutuwa Community Foundation below. If you can’t find an answer to your question here, please <a href='/contact'>contact us.</a>",
	],
		faqs: [
		{
			title: "What are the requirements for applying for funding?",
			answer: "Our Foundation does not accept unsolicited requests for funding.",
		},
		{
			title: "Is my organisation eligible to receive funding through the Foundation?",
			answer: "Our Foundation works with existing and registered non-profit organisations in our three thematic areas: early childhood development, schooling and post-schooling. Initiatives funded need to align with our vision, mission, strategic objectives and values. There are also further criteria used to screen all initiatives, ensuring they yield the desired, lasting impact and systemic change which our Foundation seeks to contribute towards.",
		},
		{
			title: "Why does the Foundation focus on three particular areas?",
			answer: "Through the three thematic areas, we can action a clear life-cycle approach to youth development, beginning with improving the quality of intervention in a child’s earliest years, then contributing to a more effective education system and finally easing the transition for young people towards being productive citizens who can access economic opportunities. We also believe that these thematic areas deal with the most urgent needs in the South African economy and society and that investment in these areas will result in significant, enduring social impact.",
		},
		{
			title: "In what geographic areas does the Foundation make grants?",
			answer: "Our Foundation funds initiatives across all nine provinces in South Africa.",
		},
		{
			title: "How can I reach the Foundation?",
			answer: "Please see the <a href='/contact'>contact us</a> page.",
		},
		{
			title: "Is the Foundation part of Standard Bank’s corporate social investment programme?",
			answer: "Our Foundation is a separate entity from the Corporate Social Investment department of The Standard Bank of South Africa Limited. For more information on the bank’s CSI, <a href='https://www.standardbank.com/sbg/standard-bank-group/why-we-matter/report-to-society' target='_blank'>click here</a>.",
		},
	]
};

export { faqsData };
