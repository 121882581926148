import React, { useState } from 'react'
import Countries from '../../../data/contact/contact-countries'
import Intro from '../../atoms/intro/intro'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import './contact-form.scss'

const initialValues = {
	name: '',
	surname: '',
	country: '',
	contact: '',
	email: '',
	organisation: '',
	message: '',
}

const validationSchema = Yup.object({
	name: Yup.string().required('Required'),
	surname: Yup.string().required('Required'),
	country: Yup.string().required('Required'),
	contact: Yup.string().required('Required'),
	email: Yup.string().email('Invalid email format').required('Required'),
	organisation: Yup.string().required('Required'),
	//message: Yup.string().required('Required'),
})

function ContactForm(props) {
	const { title, description } = props

	const [captchaBool, setCaptchaBool] = useState(false)

	const toggleCaptcha = () => {
		setCaptchaBool(true);
	}


	const onSubmit = (values, submitProps) => {
		if (!captchaBool) return false

		console.log('Form data', values)
		console.log('submitProps', submitProps)
		props.formSubmitted()


		submitProps.setSubmitting(false)
		submitProps.resetForm()
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		const mailTo = 'tutuwafoundation@standardbank.co.za';
		const [, name, surname, country, contact, email, organisation, message] = e.target;

		const mailToString = `mailto:${mailTo}?subject=Please%20get%20in%20touch%20with%20me&body=Good%20day,%0d%0d${message.value}%0d%0dRegards,%0d${name.value}%0d${surname.value}%0d${country.value}%0d${contact.value}%0d${email.value}%0d${organisation.value}`;
		window.location.href = mailToString;
		
	}

	return (
		<div className="content contact-form">
			<Intro title={title} description={description}></Intro>

			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>

				{({ errors, touched }) => (

					<Form onSubmit={e => { handleSubmit(e) }}>

						<fieldset className="contact-form__fieldset">

							<div className={`contact-form__group
                                ${errors.name && touched.name ? 'contact-form__group--error' : ''}
                                ${!errors.name && touched.name ? 'contact-form__group--complete' : ''}`}
							>

								<Field
									className="contact-form__input"
									type="text"
									name="name"
									id="name"
									required
								/>

								<label className="contact-form__label" htmlFor="name">
									Name<sup>*</sup>
								</label>

								<ErrorMessage
									className="contact-form__error"
									component="span"
									name="name"
								/>

							</div>

							<div className={`contact-form__group
                                ${errors.surname && touched.surname ? 'contact-form__group--error' : ''}
                                ${!errors.surname && touched.surname ? 'contact-form__group--complete' : ''}
                            `}>

								<Field
									className="contact-form__input"
									type="text"
									name="surname"
									id="surname"
									required
								/>

								<label className="contact-form__label" htmlFor="surname">
									Surname<sup>*</sup>
								</label>

								<ErrorMessage
									className="contact-form__error"
									component="span"
									name="surname"
								/>
							</div>

							<div className={`contact-form__group
                                ${errors.country && touched.country ? 'contact-form__group--error' : ''}
                                ${!errors.country && touched.country ? 'contact-form__group--complete' : ''}
                            `}>

								<Field
									className="contact-form__input"
									as="select"
									placeholder="Please"
									name="country"
									id="country"
								>

									<option value>Please select a country</option>

									{Countries.map((country, index) => (

										<option key={index} value={country}>
											{country}
										</option>

									))}
								</Field>

								<label className="contact-form__label" htmlFor="country">
									Country<sup>*</sup>
								</label>

								<ErrorMessage
									className="contact-form__error"
									component="span"
									name="country"
								/>
							</div>

							<div className={`contact-form__group
                                ${errors.contact && touched.contact ? 'contact-form__group--error' : ''}
                                ${!errors.contact && touched.contact ? 'contact-form__group--complete' : ''}
                            `}>

								<Field
									className="contact-form__input"
									type="text"
									name="contact"
									id="contact"
									required
								/>

								<label className="contact-form__label" htmlFor="contact">
									Contact<sup>*</sup>
								</label>

								<ErrorMessage
									className="contact-form__error"
									component="span"
									name="contact"
								/>
							</div>

							<div className={`contact-form__group
                                ${errors.email && touched.email ? 'contact-form__group--error' : ''}
                                ${!errors.email && touched.email ? 'contact-form__group--complete' : ''}
                            `}>

								<Field
									className="contact-form__input"
									type="text"
									name="email"
									id="email"
									required
								/>

								<label className="contact-form__label" htmlFor="email">
									Email<sup>*</sup>
								</label>

								<ErrorMessage
									className="contact-form__error"
									component="span"
									name="email"
								/>
							</div>

							<div className={`contact-form__group 
                                ${errors.organisation && touched.organisation ? 'contact-form__group--error' : ''}
                                ${!errors.organisation && touched.organisation ? 'contact-form__group--complete' : ''}
                            `}>
								<Field
									className="contact-form__input"
									type="text"
									name="organisation"
									id="organisation"
									required
								/>

								<label className="contact-form__label" htmlFor="organisation">
									Organisation<sup>*</sup>
								</label>

								<ErrorMessage
									className="contact-form__error"
									component="span"
									name="organisation"
								/>
							</div>

							<div className={`contact-form__group  contact-form__group--full
                                ${!errors.message && touched.message ? 'contact-form__group--complete' : ''}
                            `}>
								<Field
									className="contact-form__textarea"
									as="textarea"
									name="message"
									id="message"
									required
								/>

								<label className="contact-form__label" htmlFor="message">
									Message
								</label>

								<ErrorMessage
									className="contact-form__error"
									component="span"
									name="message"
								/>
							</div>

							<div className="contact-form__group">

								<div className="contact-form__info">

									<p>Your information will be managed securely</p>

								</div>

								<div className="submitgroup__button">

									<button className="button" type="submit">SUBMIT</button>

								</div>

							</div>

						</fieldset>

					</Form>

				)}

			</Formik>

		</div>
	)
}

export default ContactForm
