
import React from 'react';

import './logo.scss';

const Logo = () => {

	return (

		<a href="/index.html">
								
			<img className="logo"
				src="../../../assets/images/logo.png"
				alt="Tutuwa"
			/>
							
		</a>
        

	)

}

export default Logo;
