import React,{} from "react";
import { NavLink } from "react-router-dom";

import "./cookie-policy.scss";

const CookiePolicy= (props) => {

	function toggleMenu() {
		const nav = document.getElementById("js-cookie");
		nav.classList.toggle("cookie-policy--open");
	}

	return (
			
		<div className="cookie-policy cookie-policy--open" id="js-cookie">

			<div className="cookie-policy__content">

				<p>

					We use cookies to make your experience with us better. By continuing to use our website
					without changing the settings, you are agreeing to our use of cookies. To find out more see
					our <NavLink to="/legal">Privacy Policy</NavLink>.

				</p>

				<div className="cookie-policy__close">

					<input
						onChange={toggleMenu}
						type="checkbox"
					/>

					<button></button>
					
				</div>

			</div>

		</div>
		
	);

};

export default CookiePolicy;
