
import React from 'react'

import './map.scss'

const Map = (props) => {

	const {
		content
	} = props;

	return (

		<div className="map">

			<iframe
				title="map"
				width="100%"
				height="100%"
				id="gmap_canvas"
				src={content}
				frameBorder="0"
				scrolling="no"
				marginHeight="0"
				marginWidth="0">

			</iframe>

		</div>

	)
}

export default Map
