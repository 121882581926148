
import React from 'react';

import './link.scss';

const Link = (props) => {

	const {
		modifier,
		target,
		text,
		url
	} = props;

	return (

		<a href={url}
			target={target}
			className={`link ${modifier ? `link--${modifier}` : ''}`}>
			{text}

		</a>

	)

}

export default Link;
