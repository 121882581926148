
import React from "react";
import Helmet from "react-helmet";

import { bannerData } from '../data/home/banner';
import { highlightsData } from '../data/home/highlights';
import { infographicHeading } from '../data/home/infographic';
import { publicationsData } from '../data/home/publications';

import CookiePolicy from '../components/organisms/cookie-policy/cookie-policy';
import BannerCarousel from '../components/organisms/banner-carousel/banner-carousel';
import HighlightCarousel from '../components/organisms/highlight-carousel/highlight-carousel';
import Infographic from '../components/organisms/infographic/infographic';
import ArticleCarousel from '../components/organisms/article-carousel/article-carousel'

class HomePage extends React.Component {
    
	render() {
		return (
			<div>

                <Helmet>
                    <title>Introducing the Standard Bank Tutuwa Community Foundation</title>
                    <meta name="title" content="Introducing the Standard Bank Tutuwa Community Foundation"/>
                    <meta name="description" content="Harnessing the potential of South Africa’s youth. We invest in social impact bonds to tackle a range of social issues."/>
                    <meta name="keywords" content="standard bank tutuwa community foundation; standard bank tutuwa; tutuwa foundation; south african youth; investing in south africa’s youth; social impact initiatives; promotion of economic development; alleviation of poverty "/>
                    <meta name="robots" content="index, follow"/>
                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                    <meta name="language" content="English"/>
                </Helmet>

				<CookiePolicy />

                <BannerCarousel slides={bannerData}/>

				<div className="scroll" 
					id="highlights">
				</div>

				<div className="content-wrapper">
					<HighlightCarousel 
						title={highlightsData.title}
						description={highlightsData.description}
						slides={highlightsData.highlights}/>
				</div>

				<div className="scroll" 
					id="impact">
				</div>

				<div className="content-wrapper content-wrapper--blue">
					<Infographic 
						title={infographicHeading.title}
						description={infographicHeading.description} />
				</div>

				<div className="scroll" 
					id="publications">
				</div>

				<div className="content-wrapper">
					<ArticleCarousel 
						title={publicationsData.title}
						description={publicationsData.description}
						slides={publicationsData.publications}/>
				</div>
                
			</div>

		);
	}
}

export default HomePage;
