
import React from "react";
import { NavLink } from "react-router-dom";

import ResponsiveImage from '../../atoms/responsive-image/responsive-image';
import Pattern from '../../atoms/pattern/pattern';
import Button from '../../atoms/button/button';

import "./banner.scss";

const Banner = (props) => {

	const scrollWithOffset = (el) => {
        el.scrollIntoView({behavior: "smooth", block: "start"});
	};

	const {
		content
	} = props;


	return (

		<div className="banner">

			<div className="content">
				
				<div className="banner__content">

					<h1 className="banner__title">
						{content.title}
					</h1>

					<p className="banner__text">
						{content.description}
					</p>

					<NavLink
						className="button"
						to={content.button.url}
					>
						{content.button.text}
					</NavLink>

				</div>

			</div>

			<div className="banner__background">

				<div className="banner__overlay"></div>

				<div className="banner__pattern">
					<Pattern/>
				</div>

				<div className="banner__image">

					<ResponsiveImage 
						mobile={content.image.mobile}
						desktop={content.image.desktop}
						alt={content.image.alt}>
					</ResponsiveImage>

				</div>
				

			</div>

		</div>
        
	);
};

export default Banner;
