
import React, { useState } from 'react'

import Intro from '../../atoms/intro/intro';

import ReportList from '../../molecules/report-list/report-list'
//import ReportItem from '../../molecules/report-item/report-item'

import './reports.scss'

function Reports (props) {

	const {
		reports,
		title,
		description
	} = props;

	const [reportState, setReportState] = useState(0)
	const labels = reports.map((report) => report.title).flat(1)

	return (

		<div className="content reports">

			<Intro
				title={title}
				description={description}>
			</Intro>

			<div className="reports__scroll">

				<ul className="reports__nav">

					{labels.map((label, index) => (

						<li className={`reports__nav-item reports__nav-item--${labels[reportState]===label ? 'active' : null}`}
							key={index}
							onClick={() => setReportState(index)}>

								{label}
							
						</li>

					))}

				</ul>
				
			</div>
			
			
			<ReportList
						
				content={reports[reportState]}>

			</ReportList>

		</div>

	)
}

export default Reports;
