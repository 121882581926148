import React, { useState, useEffect } from 'react'

import imagesData from '../../../data/media-and-news/images'
import videosData from '../../../data/media-and-news/videos'

import Intro from '../../atoms/intro/intro'
import GalleryCard from '../../molecules/gallery-card/gallery-card'

import { SwiperSlide } from 'swiper/react'
import Carousel from '../carousel/carousel'
import ModalImage from '../modal-image/modal-image'
import ModalVideo from '../modal-video/modal-video'

import './gallery.scss'

function debounce(fn, ms) {
    let timer
    return (_) => {
        clearTimeout(timer)
        timer = setTimeout((_) => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    }
}

const carouselConfig = {
    slidesPerView: 1.3,
    spaceBetween: 5,
    centeredSlides: true,
    initialSlide: 1,
    navigation: {
        nextEl: '.swiper-button-next--outside',
        prevEl: '.swiper-button-prev--outside'
    },
    pagination: false,
    breakpoints: {
        480: {
            slidesPerView: 1.8,
            spaceBetween: 10,
        },
        640: {
            slidesPerView: 2.3,
            spaceBetween: 10,
        },
    }

}


const Gallery = (props) => {
	const [modalState, setModalState] = useState(false)
	const [currentGallery, setCurrentGallery] = useState({
		coverImage: { src: '', alt: '' },
		description: '',
		icon: { src: '', alt: '' },
		images: [],
		title: '',
	})

	const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    })

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            })
        }, 350)

        window.addEventListener('resize', debouncedHandleResize)
        return (_) => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    })

	const galleryClickHandler = (gallery) => {
		setCurrentGallery(gallery);
        setModalState(true)
	}

	const type = props.type

	let galleryData

	if (type === 'images') {
		galleryData = imagesData.imagesData
	}

	if (type === 'videos') {
		galleryData = videosData.videosData
	}

	return (

		<div className={`gallery gallery--${type}`}>

			<div className="content">

				<Intro
					title={galleryData.title}
					description={galleryData.description} 
				/>

				<div className="gallery__content">

					<div className="swiper-button-prev swiper-button-prev--outside"></div>
					<div className="swiper-button-next swiper-button-next--outside"></div>

					{dimensions.width <= 768 ? (

						<Carousel config={carouselConfig}>

							{galleryData.galleries.map((gallery, index) => (

								<SwiperSlide key={index}>

									<GalleryCard
										gallery={gallery}
										key={index}
										type={type}
										galleryClick={galleryClickHandler}
									/>

								</SwiperSlide>

							))}

						</Carousel>

					) : (
					
						galleryData.galleries.map((gallery, index) => (
							<GalleryCard
								gallery={gallery}
								key={index}
								type={type}
								galleryClick={galleryClickHandler}
							/>
						))

					)}

				</div>

			</div>

            {type === 'images' ? (
                <ModalImage
                    type={type}
                    isOpen={modalState}
                    gallery={currentGallery}
                    onClose={() => setModalState(false)}
			    />):null}



            {type === 'videos' ? (
                <ModalVideo
                    type={type}
                    isOpen={modalState}
                    gallery={currentGallery}
                    onClose={() => setModalState(false)}
			    />):null}

		</div>
	)
}

export default Gallery
