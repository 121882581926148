import React from 'react'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis } from 'recharts'

const renderShape = (key, pixel = 10) => ({height, width, fill, x, y, ...rest}) => {

	return (

		<svg x={x} y={y} fill="none" xmlns="http://www.w3.org/2000/svg">
			<defs>
				<linearGradient id={key} x1={0} y1={0} x2={0} y2={1}>
					<stop offset="1%" stopColor="#ffb31a" />
					<stop offset="2%" stopColor="rgb(255,179,26,0.6)" />
					<stop offset="100%" stopColor="rgb(255,255,255,0.0)" />
				</linearGradient>
			</defs>
			<rect fill={`url(#${key})`} width={width} height={height} />
		</svg>


	)
}

const InvestmentGraphBar = (props) => {

	const {graphData} = props;
	
	return (

		<div className="infographic__period">

			<h3 className="infographic__slide-item-title">
				Investment Period: <span>2016 – 2024</span>
			</h3>

			
			<div style={{ width: '100%', height: 300 }}>

				<ResponsiveContainer>

					<BarChart width={500} height={300} data={graphData}>

						<XAxis
							stroke="#eeeeee"
							dataKey="name"
							style={{ fontFamily: 'BentoSans-Regular' }}
						/>

						<YAxis 
							stroke="#eeeeee" 
							style={{ fontFamily: 'BentoSans-Regular' }} />

						<Bar 
							dataKey={'val'} 
							shape={renderShape('a')} />

					</BarChart>

				</ResponsiveContainer>

			</div>

		</div>

		

	)
}

export default InvestmentGraphBar
