
const introBannerData = {
	background: {
		desktop: "/assets/images/intro-banner/banner-about.jpg",
		mobile: "",
		alt: "Who we are",
	},
	title: "Who we are",
	description: "Helping young South Africans fulfil their potential and make an active contribution to their community."
}

export { introBannerData };
