import React from "react";

import { Swiper } from "swiper/react";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/effect-fade/effect-fade.scss";

import "./carousel.scss";

SwiperCore.use([Navigation, Pagination, A11y]);

const Carousel = (props) => {

	const { config } = props;

	return (

		<div className="carousel">

			<Swiper {...config}>{props.children}</Swiper>

		</div>
		
	);

};

export default Carousel;
