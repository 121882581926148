import React, { useEffect, useState } from 'react'

import Intro from '../../atoms/intro/intro'
import InfoCard from '../../molecules/info-card/info-card'

import { SwiperSlide } from 'swiper/react'
import Carousel from '../carousel/carousel'

import './info-carousel.scss'

function debounce(fn, ms) {
	let timer
	return (_) => {
		clearTimeout(timer)
		timer = setTimeout((_) => {
			timer = null
			fn.apply(this, arguments)
		}, ms)
	}
}

const InfoCarousel = (props) => {

	const [dimensions, setDimensions] = useState({
		height: window.innerHeight,
		width: window.innerWidth,
	})

	useEffect(() => {
		const debouncedHandleResize = debounce(function handleResize() {
			setDimensions({
				height: window.innerHeight,
				width: window.innerWidth,
			})

		}, 350)

		window.addEventListener('resize', debouncedHandleResize)
		return (_) => {
            window.removeEventListener('resize', debouncedHandleResize)
            
		}
        
	})

	const carouselConfig = {
		slidesPerView: 1.3,
		spaceBetween: 5,
		centeredSlides: true,
		initialSlide: 1,
		watchOverflow: true,
		navigation: {
			nextEl: `.swiper-button-next--outside-${props.type}`,
			prevEl: `.swiper-button-prev--outside-${props.type}`,
            lockClass: 'swiper-button-lock'

		},
		pagination: false,
		breakpoints: {
			480: {
				slidesPerView: 1.6,
				spaceBetween: 10,
				initialSlide: 1,
			},
			620: {
				slidesPerView: 2.2,
				spaceBetween: 10,
				initialSlide: 1,
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 15,
				centeredSlides: false,
				initialSlide: 0,
				
			},
			920: {
				slidesPerView: 3,
				spaceBetween: 10,
				centeredSlides: false,
				initialSlide: 0,
			},
			1280: {
				slidesPerView: 4,
				spaceBetween: 20,
				centeredSlides: false,
				initialSlide: 0,
			},
		},
	}

	const { 
		slides, 
		title, 
		description 
	} = props

	const swiperSlides = slides.map((item, index) => {
		return (

			<SwiperSlide key={index} effect="fade">

				<InfoCard
					content={item}
					index={index}
					length={slides.length}>
				</InfoCard>

			</SwiperSlide>
		)
	})

    const slidesInfo = slides.map((item, index) => {
		return (

            <InfoCard
                content={item}
                index={index}
                length={slides.length}
                key={index}>
			</InfoCard>
			
		)
	})

    const slidesLength = slides.length;


	return (

		<div className="content info-carousel">

			<Intro 
				title={title} 
				description={description}>
			</Intro>

			<div className="info-carousel__content">

                <div className={`swiper-button-prev swiper-button-prev--outside-${props.type}`}></div>
                <div className={`swiper-button-next swiper-button-next--outside-${props.type}`}></div>
                
				{dimensions.width >= 480 ? (

				<Carousel config={carouselConfig}>
					{swiperSlides}
				</Carousel>

				):  slidesInfo }

			</div>

		</div>

	)
}

export default InfoCarousel
