import React from "react";
import Helmet from "react-helmet"

import { introBannerData } from '../data/programmes/intro-banner';
import { introData } from '../data/programmes/intro';
import { partnersData } from '../data/programmes/partners';
import { reportsData } from '../data/programmes/reports';

import BreadCrumb from '../components/atoms/bread-crumb/breadcrumb';
import IntroBanner from '../components/molecules/intro-banner/intro-banner';
import IntroCard from '../components/molecules/intro-card/intro-card';
import HighlightCarousel from '../components/organisms/highlight-carousel/highlight-carousel';
import Reports from '../components/organisms/reports/reports';

class Programmes extends React.Component {

	render() {

		return (

			<div className="programmes">

                <Helmet>
                    <title>Standard Bank Tutuwa Community Foundation: Our Programmes</title>
                    <meta name="title" content="Standard Bank Tutuwa Community Foundation: Our Programmes"/>
                    <meta name="description" content="We fund and invest in initiatives specially chosen to have maximum input in alleviating poverty in South Africa."/>
                    <meta name="keywords" content="Standard bank; tutuwa foundation; poverty alleviation; poverty alleviation initiatives; about tutuwa community foundation; non-profit organisations; social impact; non-profit organisations; social impact organisations; profit and non profit organisation"/>
                    <meta name="robots" content="index, follow"/>
                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                    <meta name="language" content="English"/>
                </Helmet>


				<IntroBanner
                    title={introBannerData.title}
					description={introBannerData.description}
					button={introBannerData.button}
                    background={introBannerData.background}>
				</ IntroBanner>

				<nav>
					<BreadCrumb section="Programmes" />
				</nav>

				<div className="content-wrapper">
					<IntroCard 
						title={introData.title}
						subTitle={introData.subTitle}
						description={introData.description}
						link={introData.link}
						image={introData.image}>
					</ IntroCard>
				</div>

				<div className="scroll" 
					id="our-partners">
				</div>

				<div className="content-wrapper content-wrapper--gray" >
					<HighlightCarousel 
						title={partnersData.title}
						description={partnersData.description}
						slides={partnersData.highlights}/>
				</div>

				<div className="scroll" 
					id="reports">
				</div>

				<div className="content-wrapper content-wrapper--blue" >
					<Reports
						title={reportsData.title}
						description={reportsData.description}
						reports={reportsData.reports}>
					</Reports>
				</div>

			</div>

		);

	}

}

export default Programmes;
