import React, { useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Thumbs } from 'swiper/core'
import Image from '../../atoms/image/image'

import './gallery-carousel.scss'


SwiperCore.use([Thumbs])

const GalleryCarousel = (props) => {

	const [thumbsSwiper, setThumbsSwiper] = useState(null)

	const galleryConfig = {
		spaceBetween: 0,
		slidesPerView: 1,
		watchOverflow: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}
	}

	const thumbsConfig = {
		spaceBetween: 10,
		slidesPerView: "auto",
		freeMode: true,
      	watchSlidesVisibility: true,
      	watchSlidesProgress: true,
		pagination: {
			clickable: true
		}
	}

	const { gallery } = props

	return (

		<div className="gallery-carousel">

			<div className="gallery-carousel__gallery">

				<div className="swiper-button-prev swiper-button-prev--outside"></div>
				<div className="swiper-button-next swiper-button-next--outside"></div>

				<Swiper {...galleryConfig} 
					thumbs={{ swiper: thumbsSwiper }}>

					{gallery.images.map((image, index) => (

						<SwiperSlide key={index}>

							<Image src={image.src} 
								alt={image.alt} />

						</SwiperSlide>

					))}

				</Swiper>

			</div>

			<div className="gallery-carousel__thumbs">
				
				<Swiper {...thumbsConfig}
					onSwiper={setThumbsSwiper}
					watchSlidesVisibility
					watchSlidesProgress
				>

					{gallery.images.map((image, index) => (
						
						<SwiperSlide key={index}>

							<Image src={image.src} 
								alt={image.alt} />

						</SwiperSlide>

					))}

				</Swiper>

			</div>

		</div>
	)
}

export default GalleryCarousel
