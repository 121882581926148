
import React from "react";

import Image from '../../atoms/image/image';
import Tag from '../../atoms/tag/tag';
import Link from '../../atoms/link/link';
import SlideCounter from '../../atoms/slide-counter/slide-counter';

import "./highlight-card.scss";

const HighlightCard = (props) => {

	const {
		content
	} = props;

	function sanitize(item) {

		return {
			__html: item
		};
		
	}

	return (

		<div className="highlight-card">
            
			<div className="highlight-card__overlay"></div>

			<div className="highlight-card__image">

				<Tag content={content.tag} />

				<Image 
					src={content.image.src}
					alt={content.image.alt}>
				</Image>

				{content.logo && (
				
					<div className="highlight-card__logo">
						<img src={content.logo.src}
							alt={content.logo.alt} />
					</div>

				)}

			</div>

			<div className="highlight-card__content">

				<div className="highlight-card__counter">

					<SlideCounter
                    	start={props.index+1}
                    	end={props.length}/>
					
				</div>

				<div className="highlight-card__description">

					<h3>
						{content.title}
					</h3>

					{content.subtitle && (

						<h5>
							{content.subtitle}
						</h5>

					)}

					{content.description.map((item, index) => {

						return (

							<p dangerouslySetInnerHTML={ sanitize(item) }
								key={index}>
							</p>

						);
												
					})}

					{content.link && (

						<Link 
							modifier={content.link.modifier}
							target={content.link.target}
							text={content.link.text}
							url={content.link.url}>
						</Link>

					)}

				</div>

			</div>

		</div>
        
	);
};

export default HighlightCard;
