
import React from "react";

import Intro from '../../atoms/intro/intro';

import { SwiperSlide } from "swiper/react";
import Carousel from "../carousel/carousel";
import ArticleCard from "../../molecules/article-card/article-card";

import "./article-carousel.scss";

const carouselConfig = {
	slidesPerView: 1.3,
	spaceBetween: 5,
	centeredSlides: true,
	initialSlide: 1,
	navigation: {
		nextEl: '.swiper-button-next--outside',
		prevEl: '.swiper-button-prev--outside'
	},
	pagination: false,
	watchOverflow: true,
	breakpoints: {
		640: {
			slidesPerView: 1.6,
			spaceBetween: 10,
		},
        768: {
		  slidesPerView: 2,
		  spaceBetween: 15,
		  centeredSlides: false,
		  initialSlide: 0,
        },
        1024: {
		  slidesPerView: 3,
		  spaceBetween: 20,
		  centeredSlides: false,
		  initialSlide: 0,
		},
	}
};


const ArticleCarousel = (props) => {

	const {
		slides,
		title,
		description
	} = props;
	
	return (

		<div className="content article-carousel">

			<Intro
				title={title}
				description={description}>
			</Intro>

			<div className="article-carousel__content">

				<div className="swiper-button-prev swiper-button-prev--outside"></div>
				<div className="swiper-button-next swiper-button-next--outside"></div>

				<Carousel config={carouselConfig}>

					{slides.map((item, index) => {

						return (
							
							<SwiperSlide 
								key={index} 
								effect="fade">

								<ArticleCard 
									content={item} index={index} length={slides.length}>					
								</ArticleCard>
								
							</SwiperSlide>
						);

					})}

				</Carousel>
			
			</div>

		</div>

	);
};

export default ArticleCarousel;
