
const milestonesData= {
	title: "Milestones",
	description: [
		'The Tutuwa Community Trust was formed in 2004 as part of the Standard Bank Group’s (SBG) black ownership initiative. The Trust enabled the establishment in 2016 of a non-profit company, The Standard Bank Tutuwa Community Foundation, as a separate entity from the bank’s corporate social investment initiatives.',
		'It was decided that the Foundation’s work should be directed towards harnessing South Africa’s ‘demographic dividend’: the economic potential (productive and ultimately fiscal) of many young people moving into and through the workforce. For more information on the history of our Foundation and the various transactions that led up to our launch, see the timeline below.',
	],
	background: {
		desktop: "/assets/images/intro-banner/intro-banner.png",
		mobile: "/assets/images/intro-banner/intro-banner-mobi.png",
		alt: "The Standard Bank Tutuwa Community Foundation",
	},
	image: {
		src: "/assets/images/milestones.jpg",
		alt: "The Standard Bank Tutuwa Community Foundation",
	},
	event: [
		{
			year: "2004",
			title: '"The SBG’s black ownership initiative, known as Tutuwa, launches ',
			description: "Shares priced at R42.50 per share",
		},
		{
			year: "2006",
			title: 'SMME participants join the scheme',
			description: "250 small- and medium-sized enterprises are selected",
		},
		{
			year: "2008",
			title: 'Shares sold to the Industrial and Commercial Bank of China (ICBC)',
			description: "The ICBC bought 11.11% of shares as part of its acquisition of 20% of SBG",
		},
		{
			year: "2010",
			title: 'The start of dividend payments to beneficiaries',
			description: "Tutuwa Managers Trust shares vest fully",
		},
		{
			year: "2010",
			title: 'Dividend payments continue',
			description: "R1.9bn dividends distributed to all Tutuwa beneficiaries",
		},

		{
			year: "2014",
			title: 'Tutuwa’s ten year lock-in period ends',
			description: "Shares priced at R143.48 per share",
		},
		{
			year: "2015",
			title: 'Tax paid on behalf of Managers Trust beneficiaries',
			description: "More than R1 billion in PAYE paid over to SARS",
		},
		{
			year: "2015",
			title: 'SMMEs exit scheme',
			description: "More than R10.7 billion created for beneficiaries",
		},
		{
			year: "2016",
			title: 'The Standard Bank Tutuwa Community Foundation is born',
			description: "High-school Scholarship Programme launches",
		},
		{
			year: "2019",
			title: 'The Tutuwa scheme ends',
			description: "All remaining debt is settled",
		},
		{
			year: "2021",
			title: 'Our Foundation’s inception strategy comes to an end',
			description: "New 2021-2025 strategy implemented",
		},
	]

};

export { milestonesData };
