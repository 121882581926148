
import React from "react";

import { 
	BrowserRouter as Router, 
	Route, 
	Switch } from "react-router-dom";

import routesConfig from './routes';

import Header from './components/organisms/header/header';
import Footer from './components/organisms/footer/footer';

function App() {
	return (

		<Router>

			<Header></Header>

			<main>

				<Switch>

					{routesConfig.map((route, i) => (

						<Route key={i}
							{...route} />

					))}

				</Switch>

			</main>

			<Footer></Footer>

		</Router>
	);
}

export default App;
