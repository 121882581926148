
import React from "react";

import Image from '../../atoms/image/image';
import Tag from '../../atoms/tag/tag';
import Link from '../../atoms/link/link';
import Icon from '../../atoms/icon/icon';

import "./article-card.scss";

const ArticleCard = (props) => {

	const {
		content,
	} = props;
	return (

		<div className="article-card">

			<div className={`article-card__image article-card__image--${content.image.type}`}>

				{content.tag && (
			
					<Tag content={content.tag} />

				)}

				{content.icon && (

					<Icon
						src={content.icon.src}
						alt={content.icon.alt}
						url={content.icon.url}
						target={content.icon.target} />

				)}

				<a href={content.link.url}
					target={content.link.target} >

					<Image 
						src={content.image.src}
						alt={content.image.alt} />

				</a>
				
			
			</div>

			<div className="article-card__content">

				<div>

					<h4>
						{content.title}
					</h4>

					<p>
						{content.description}
					</p>
					
				</div>

				<div>

					{content.link && (

						<Link 
							modifier={content.link.modifier}
							target={content.link.target}
							text={content.link.text}
							url={content.link.url}>
						</Link>
						
					)}

				</div>

			</div>
			

		</div>
        
	);
};

export default ArticleCard;
