
import React from 'react'

function InvestmentAreas(props) {
	const isActive = (val) => {
		return (
			active.replace(/\s+/g, '-').toLowerCase() ===
			val.replace(/\s+/g, '-').toLowerCase()
		)
	}

	const { areas, active } = props

	const areasItems = areas.map((area) => (

		<div 
            className={`infographic__areas-item ${ isActive(area) ? ` active` : `` } `}
			onClick={() => props.investAreaSelect(area)}
            key={area}>

				<span>
					{area}
				</span>

		</div>

	))

	return areasItems
}

export default InvestmentAreas
