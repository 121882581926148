import React, { useRef, useEffect } from 'react'

import './range-slider.scss'

const RangeSlider = (props) => {
	const sliderRef = useRef()

	useEffect(() => {
		sliderRef.current.value = props.selectedIndex
	}, [props.selectedIndex])

	const milestones = props.milestones

	let msLabelsLeft = []
	let msLabelsRight = []

	let labelCheck = false

	milestones.map((milestone,index) => {

		if (labelCheck === 'left') {
            msLabelsRight.push(<div className="range-slider__label-item" key={index}>{milestone.year}</div>)
			msLabelsLeft.push(<div className="range-slider__label-spacer" key={index}></div>)
			labelCheck = 'right'
		} 
		
		else if (labelCheck === 'right') {
            msLabelsLeft.push(<div className="range-slider__label-item" key={index}>{milestone.year}</div>)
			msLabelsRight.push(<div className="range-slider__label-spacer" key={index}></div>)
			labelCheck = 'left'
		}

		if (!labelCheck) {
            msLabelsLeft.push(<div className="range-slider__label-item" key={index}>{milestone.year}</div>)
			msLabelsRight.push(<div className="range-slider__label-spacer" key={index}></div>)
			labelCheck = 'left'
		}
        return true

	})

	return (
		<div className="range-slider">

			<div className="range-slider__label range-slider__label--right">
				{msLabelsRight}
			</div>

			<input
				className="range-slider__timeline"
				ref={sliderRef}
				orient="vertical"
				type="range"
				min={0}
				max={props.max}
				step={1}
				onChange={(e) => props.selectYearHandler(e.target.value)} // only set state when handle is released
			/>

			<div className="range-slider__label range-slider__label--left">
				{msLabelsLeft}
			</div>
            
		</div>
	)
}
export default RangeSlider
