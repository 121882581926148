
const bannerData = [
	{
		image: {
			desktop: "/assets/images/homepage-banner/homepage-banner-01.jpg",
			mobile: "/assets/images/homepage-banner/homepage-banner-01-mobi.jpg",
			alt: "The Standard Bank Tutuwa Community Foundation",
		},
		title: "The Standard Bank Tutuwa Community Foundation",
		description: "Harnessing the potential of South Africa’s youth",
		button: {
			target: "_self",
			text: "More about us",
			url: "/about",
		},
	}
];

export { bannerData };
