
import React from "react";
import { NavLink } from "react-router-dom";

import Logo from '../../atoms/logo/logo';

import './footer.scss'


const Footer = props => {

	return (

        <footer>

		    <div className="footer">

				<div className="fullwidth-content footer__content">

					<div className="footer__logo">

						<Logo />

					</div>

					<ul className="footer__nav">

						<li className="footer__nav-item">

							<NavLink
									className="footer__nav-link"
									to="/sitemap"
								>
									Sitemap
							</NavLink>

						</li>

						<li className="footer__nav-item">

							<NavLink
									className="footer__nav-link"
									to="/terms-and-conditions"
								>
									Terms & Conditions
							</NavLink>

						</li>

						<li className="footer__nav-item">

							<NavLink
									className="footer__nav-link"
									to="/legal"
								>
									Legal
							</NavLink>

						</li>

					</ul>

					<div className="footer__copyright">
						©2016 The Standard Bank Tutuwa Community Foundation NPC. The Standard Bank Tutuwa Community Foundation NPC is a registered non-profit company.
					</div>

				</div>

            </div>

		</footer>

	)

}

export default Footer;
