
import React from 'react'

import Image from '../../atoms/image/image';

import "./tab-card.scss";

const TabCard = (props) => {

	const {
		content
	} = props;

	function sanitize(item) {

		return {
			__html: item
		};
		
	}

	return (

		<div className="tab-card">

			<div className="tab-card__content">

				<h2>
					{content.title}
				</h2>

				{content.subTitle && (
				
					<p className="tab-card__sub-title">
						{content.subTitle}
					</p>

				)}

				{content.description.map((item, index) => {

					return (

						<p dangerouslySetInnerHTML={ sanitize(item) }
							key={index}>
						</p>

					);
												
				})}

			</div>

			{content.image && (
			
				<div className="tab-card__image">

						<Image 
							src={content.image.src}
							alt={content.image.alt}>
						</Image>

				</div>
				
			)}
			
		</div>
	)
}

export default TabCard;
