
import React from "react";

import Image from '../../atoms/image/image';
import Link from '../../atoms/link/link';

import "./intro-card.scss";

const IntroCard = (props) => {

	const {
		title,
		subTitle,
		description,
		link,
		image
	} = props;

	function sanitize(item) {

		return {
			__html: item
		};
		
	}

	return (

		<div className="content intro-card">

			<div className="intro-card__content">

				<h2>
					{title}
				</h2>

				{subTitle && (

					<p>
						<b>
							{subTitle}
						</b>
					</p>

				)}

				{description && (

					<div>

						{description.map( (item, index) => {

							return (

								<p dangerouslySetInnerHTML={ sanitize(item) }
									key={index}>
								</p>

							)
																
						})}

					</div>

				)}
				

				{link && (

					<Link 
						modifier={link.modifier}
						target={link.target}
						text={link.text}
						url={link.url}>
					</Link>

				)}

			</div>

			<div className="intro-card__image">

				<Image 
					src={image.src}
					alt={image.alt}>
				</Image>

			</div>

		</div>
        
	);
};

export default IntroCard;
