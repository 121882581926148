import React from "react";
import Helmet from "react-helmet"

import { introBannerData } from '../data/about/intro-banner';
import { introData } from '../data/about/intro';
import { principlesData } from '../data/about/principles';
import { whatWeDoData } from '../data/about/what-we-do';
import { historyData } from '../data/about/history';
import { milestonesData } from '../data/about/milestones';
import { teamData } from '../data/about/team';
import { boardData } from '../data/about/board';
import { faqsData } from '../data/about/faqs';

import BreadCrumb from "../components/atoms/bread-crumb/breadcrumb";
import IntroBanner from '../components/molecules/intro-banner/intro-banner';
import IntroCard from '../components/molecules/intro-card/intro-card';
import InfoCarousel from '../components/organisms/info-carousel/info-carousel';
import Faqs from '../components/organisms/faqs/faqs';
import History from '../components/organisms/history/history';
import Timeline from '../components/organisms/timeline/timeline';
import Tabs from "../components/organisms/tabs/tabs";

class About extends React.Component {

    componentDidUpdate(){
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    render() {

        return (

            <div className="about">

                <Helmet>
                    <title>The Standard Bank Tutuwa Community Foundation: Who We Are </title>
                    <meta name="title" content="The Standard Bank Tutuwa Community Foundation: Who We Are "/>
                    <meta name="description" content="We aim to help young South Africans reach their full potential and make an active contribution to their community."/>
                    <meta name="keywords" content="Standard bank; tutuwa foundation; South African youth; investing in South Africa’s youth; social impact initiatives; economic development; investing in social impact bonds; impact investing; thought leadership; economic growth and development; industrial development; department of trade, industry and competition; youth empowerment; black economic empowerment"/>
                    <meta name="robots" content="index, follow"/>
                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                    <meta name="language" content="English"/>
                </Helmet>

            
				<IntroBanner
                    title={introBannerData.title}
					description={introBannerData.description}
					button={introBannerData.button}
                    background={introBannerData.background}>
				</ IntroBanner>

                <nav>
					<BreadCrumb section="About" />
				</nav>

                <div className="content-wrapper">
					<IntroCard 
						title={introData.title}
						subTitle={introData.subTitle}
						description={introData.description}
						link={introData.link}
						image={introData.image}>
					</ IntroCard>
				</div>

				<div className="scroll" 
						id="our-vision">
				</div>

				<div className="content-wrapper content-wrapper--gray">
					<Tabs tabs={principlesData} />
				</div>
                
                <div className="scroll" id="our-history">
				</div>

				<History
					title={historyData.title}
					subTitle={historyData.subTitle}
					content={historyData.content}
					contentExpanded={historyData.contentExpanded}
					link={historyData.link}
					background={historyData.background}>
				</History>

				<div className="scroll" 
						id="milestones">
				</div>
                
				<div className="content-wrapper">

					<Timeline
						title={milestonesData.title}
						description={milestonesData.description}
						background={milestonesData.background}
						image={milestonesData.image}>
					</Timeline>

					<Tabs 
						description={principlesData.description}
						tabs={whatWeDoData}>
					</Tabs>

				</div>

				<div className="scroll" 
					id="our-team">
				</div>

				<div className="content-wrapper content-wrapper--gray">

					<InfoCarousel 
						title={teamData.title}
						description={teamData.description}
						slides={teamData.team}
						type="team">                        
					</ InfoCarousel>

					<div className="scroll" 
						id="our-board">
					</div>

                    <div className="content-spacer"></div>

                    <InfoCarousel 
						title={boardData.title}
						description={boardData.description}
						slides={boardData.board}
						type="board">
					</ InfoCarousel>

				</div>

				<div className="scroll" 
					id="frequently-asked-questions">
				</div>
                
				<div className="content-wrapper content-wrapper--blue" >
					<Faqs 
						title={faqsData.title}
						description={faqsData.description}
						accordion={faqsData.faqs}>
					</Faqs>
				</div> 

            </div>

		);
		
	}
	
}

export default About;
