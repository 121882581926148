
const locationData = {
	address: {
		line1: "Standard Bank Centre",
		line2: "5 Simmonds Street ",
		line3: "8th Floor",
		line4: "Johannesburg, 2001",
	},
	email: {
		modifier: "email",
		target: "_blank",
		text: "tutuwafoundation@standardbank.co.za ",
		url: "tutuwafoundation@standardbank.co.za ",
	},
	map: "https://maps.google.com/maps?q=Standard%20Bank%20Centre%20%205%20Simmonds%20Street%20%20%20Johannesburg%20%202001&t=&z=15&ie=UTF8&iwloc=&output=embed",
};

export { locationData };
