
import React from 'react';

import './tag.scss';

const Tag = (props) => {

	const {
		content
	} = props;

	return (

		<div className="tag">

			{content}

		</div>
        

	)

}

export default Tag;
