
import React from 'react'

const InvestmentSlideContent = (props) => {

	const {
		organisations
	} = props;

	return (

		<div className="infographic__organisations">

			<h3 className="infographic__slide-item-title">
				Organisations
			</h3>

			<ul className="infographic__organisations-list">
				
				{organisations.map((item, index) => {

					return (

						<li className="infographic__organisations-list-item"
							key={index}>
							{item}
						</li>
					);
													
				})}

			</ul>

		</div>

	);

};

export default InvestmentSlideContent
