
import React from 'react'

import Link from '../../atoms/link/link';

import "./report-item.scss";

const ReportItem = (props) => {

	const {
		content
	} = props;

	return (

		<div className="report-item">

			<p className="report-item__title">
				{content.title}
			</p>

			<div className="report-item__link">

				<Link 
					modifier={content.link.modifier}
					target={content.link.target}
					text={content.link.text}
					url={content.link.url}>
				</Link>

			</div>
			
		</div>


	)
}

export default ReportItem;
