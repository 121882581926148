
import React from 'react';

import './pattern.scss';

const Pattern = () => {

	return (

		<img className="pattern"
			src="/assets/images/pattern.svg"  
			alt="pattern"/>
        
	)

}

export default Pattern;
