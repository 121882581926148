
const introData = {
	image: {
		src: "/assets/images/intro-about-us.jpg",
		alt: "Online News Article",
	},
	title: "Our Partnership Approach",
	description: [
		'Our Foundation works with existing reputable non-profit organisations in our three thematic areas – early childhood development, schooling and post-schooling – to achieve significant and enduring social impact through our initiatives. The aim of this approach is to ‘crowd in’ existing organisations to improve the effectiveness of interventions.',
		'We enter into partnerships with non-profit organisations that share our Foundation’s vision, mission, goals, values and strategic focus. These organisations must have a clear constituency, that is, communities they work for, relate to and advocate for, and they must demonstrate clearly articulated and practised accountability mechanisms for their constituency.'
	],
}

export { introData };
