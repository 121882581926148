
const historyData= {

	title: "Our History",
	subTitle: "The Standard Bank Tutuwa Community Foundation was founded in 2016. Our purpose is to benefit communities in South Africa through the promotion of economic development and the alleviation of poverty. ",
	content: [
		"Our Foundation seeks to finance projects run by developmental institutions such as non-profit and non-government organisations that are predominantly for the benefit of historically disadvantaged people in accordance with the framework established by the B-BBEE Act and the then Financial Sector Code.",
		"We invest our resources in young people, from their earliest years to their schooling and post-schooling years. Our aim is to make long-term commitments to advance their growth so that they can reach their full potential and be productive citizens who contribute to the economic development of their communities.",
	],
	contentExpanded: [
		"<b>Evidence-based approaches:</b> Whether its proven methodologies or leading practice discovery, all our pursuits are done on the basis of evidence. ",
		"<b>Leveraging public funds:</b> We acknowledge and seek to partner with public sector institutions to ensure systemic change. The principle of leverage insists that to scale interventions and deliver impact we must also influence how public money is spent.",
		"<b>Collaboration and networking:</b> The challenges we seek to resolve cannot rely on one organisation or even one type of solution. Systemic problems require a networked approach with open collaboration between all those involved. ",
		"<b>Scaling up, crowding in:</b> Working through networks and in addition to a long-term focus, we need to think “big” and also think “fast”. This means funding projects and programmes that address the needs of young people, crowding in investors and focusing on replicability.",
		"<b>Sustainability:</b> We acknowledge the importance of the United Nations Sustainable Development Goals and the African Union agenda 2063, particularly in the context of our work. In so doing, we recognise the importance of macro-scale changes that impact on humanity’s economic growth in the context of the fourth industrial revolution, environmental stewardship in the context of climate change and other impacts on the wellbeing of the youth in general.",
	],
	link: {
		text: "Read more",
		url: "",
	},
	background: {
		desktop: "/assets/images/intro-banner/intro-banner.png",
		mobile: "/assets/images/intro-banner/intro-banner-mobi.png",
		alt: "The Standard Bank Tutuwa Community Foundation",
	},
	
};

export { historyData };
