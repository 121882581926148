
const introBannerData = {
	background: {
		desktop: "/assets/images/intro-banner/banner-media.jpg",
		mobile: "",
		alt: "The Standard Bank Tutuwa Community Foundation",
	},
	title: "Media & News",
	description: "Our Foundation aims to inspire and support South African youth to reach their full potential as productive citizens. This page showcases articles, videos and pics on our work, our partners’ work and our sector."
}

export { introBannerData };
