import React from "react";

import "./filter.scss";

const Filter = (props) => {

	const filterItems = props.items.map((item) => (

		<li
			className={`filter__item filter__item--${props.active === item ? "active" : "inactive"}`}
			key={item}
			onClick={props.filterClick.bind(this, item)}
		>
			{item}
		</li>
        
	));

	return <div className="filter">

			<div className="content">

				<div className="filter__scroll">

					<ul className="filter__list">

						{filterItems}

					</ul>

				</div>
				
			</div>

		</div>
	
};

export default Filter;
