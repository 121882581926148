const videosData = {
	title: 'Videos',
	description: [
		'Hear from some of the beneficiaries of our partners’ programmes and watch clips on our three focus areas: early childhood development, schooling and post-schooling.',
	],

	galleries: [
		{
			title: 'Foundation graduation class of 2020',
			description: 'Meet some of the scholars who graduated in 2020',
			coverImage: {
				src: '/assets/images/media/videos/thumb/video-graduation.png',
				alt: 'Foundation graduation class of 2020',
			},
			icon: {
				src: '/assets/icons/icon-play-video.png',
				alt: 'View video',
			},
			embedId: 'XdHJwDGQYNE',
		},
		{
			title: 'Are SIBs the golden ticket to public private collaboration?',
			description: 'As the fiscal space to deliver much needed infrastructure and increasingly, critical social services starts to erode due to our crippling debt',
			coverImage: {
				src: '/assets/images/media/videos/thumb/sibs.png',
				alt: 'Foundation launch video (2019)',
			},
			icon: {
				src: '/assets/icons/icon-play-video.png',
				alt: 'View video',
			},
			embedId: '21T77NCJ1js',
		},
		{
			title: 'Meet an ATI beneficiary: Mary Ledwaba',
			description: 'Mary discusses the benefits of technical training',
			coverImage: {
				src: '/assets/images/media/videos/thumb/mary.png',
				alt: 'Meet an ATI beneficiary: Mary Ledwaba',
			},
			icon: {
				src: '/assets/icons/icon-play-video.png',
				alt: 'View video',
			},
			embedId: 'aovX4HhNt6U',
		},
		{
			title: 'Nali’ibali team recognise and celebrate World Read Aloud',
			description: 'As Standard Bank South Africa in partnership with the Nali’ibali team recognise and celebrate World Read Aloud Day this month ',
			coverImage: {
				src: '/assets/images/media/videos/thumb/read_aloud.png',
				alt: 'Nali’ibali team recognise and celebrate World Read Aloud Day this month',
			},
			icon: {
				src: '/assets/icons/icon-play-video.png',
				alt: 'View video',
			},
			embedId: '_Db64X2nlyo',
		},
		{
			title: 'IkamvaYouth: After-school programme partnerships',
			description: 'IkamvaYouth looks at the importance of partnerships in facilitating after-school programmes',
			coverImage: {
				src: '/assets/images/media/videos/thumb/video-after-school.jpg',
				alt: 'IkamvaYouth: After-school programme partnerships',
			},
			icon: {
				src: '/assets/icons/icon-play-video.png',
				alt: 'View video',
			},
			embedId: 'gIz43yaA-Bo',
		},
		{
			title: '2020 Child Gauge report: The slow violence of malnutrition',
			description: 'Professor Julian May discusses the report’s findings on child stunting and the impact of COVID-19 on food security',
			coverImage: {
				src: '/assets/images/media/videos/thumb/video-malnutrition.jpg',
				alt: '2020 Child Gauge report: The slow violence of malnutrition',
			},
			icon: {
				src: '/assets/icons/icon-play-video.png',
				alt: 'View video',
			},
			embedId: 'WC0ljEDUbpY',
		},
		{
			title: 'Bright Kid Foundation ',
			description: 'Excitement at the delivery of the Khanyisa Early Childhood Development Centre',
			coverImage: {
				src: '/assets/images/media/videos/thumb/video-brightkid.jpg',
				alt: 'Bright Kid Foundation ',
			},
			icon: {
				src: '/assets/icons/icon-play-video.png',
				alt: 'View video',
			},
			embedId: 'HtzaYmFpnS8',
		},
		{
			title: 'Early childhood development programmes',
			description: 'An SABC news segment on the importance of ECD programmes',
			coverImage: {
				src: '/assets/images/media/videos/thumb/video-early-childhood.jpg',
				alt: 'Early childhood development programmes',
			},
			icon: {
				src: '/assets/icons/icon-play-video.png',
				alt: 'View video',
			},
			embedId: 'OP0E-aApLas',
		},
		{
			title: 'Most employable careers',
			description: 'Minister Blade Nzimande launches a list of occupations in SA which are most likely to lead to employment',
			coverImage: {
				src: '/assets/images/media/videos/thumb/video-careers.jpg',
				alt: 'Most employable careers',
			},
			icon: {
				src: '/assets/icons/icon-play-video.png',
				alt: 'View video',
			},
			embedId: 'Kil3nG8RQL4',
		},
		{
			title: 'Matric pass rate 2020',
			description: 'Professor Mary Metcalfe discusses the overall 2020 matric pass rate for public schools: 76.2%',
			coverImage: {
				src: '/assets/images/media/videos/thumb/video-pass-rate.jpg',
				alt: 'Matric pass rate 2020',
			},
			icon: {
				src: '/assets/icons/icon-play-video.png',
				alt: 'View video',
			},
			embedId: 'QxYaavnzlwk',
		},
	],
}

export default { videosData }
