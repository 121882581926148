
import React from 'react'

import Counter from '../../atoms/counter/counter'

function InvestmentSlideApproved(props) {

	const {
		amount
	} = props;

	return (

		<div className="infographic__overall">
			
			<h3 className="infographic__slide-item-title">
				Overall Amount Approved
			</h3>

			<div className="infographic__overall-content">

				<div className="infographic__overall-counter">

					<p>
						Programme investment committed:
					</p>

					<Counter amount={amount} />

				</div>

			</div>
			

		</div>

	)
}

export default InvestmentSlideApproved
