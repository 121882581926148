import React from 'react'

import Image from '../../atoms/image/image'
import Icon from '../../atoms/icon/icon'
import Link from '../../atoms/link/link'

import './gallery-card.scss'

const GalleryCard = (props) => {
	const { gallery, type } = props

	return (
		<div className={`gallery-card gallery-card--${type}`} onClick={()=>props.galleryClick(gallery)}>

			<div className="gallery-card__thumbnail">
				<div className="gallery-card__thumbnail-overlay"></div>

				<Image
					src={gallery.coverImage.src}
					alt={gallery.coverImage.alt}
				></Image>
                
			</div>

			<div className="gallery-card__icon">
                
				<Icon src={gallery.icon.src} alt={gallery.icon.alt}></Icon>

			</div>

			<div className="gallery-card__content">
				<h4>{gallery.title}</h4>

				<p>{gallery.description}</p>

				<Link text="View"></Link>
                
			</div>
		</div>
	)
}
export default GalleryCard
