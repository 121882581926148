
const introBannerData = {
	background: {
		desktop: "/assets/images/intro-banner/banner-contact.jpg",
		mobile: "",
		alt: "Contact Us",
	},
	title: "Contact Us",
	description: "Find out more about the work we do to offer young South Africans a brighter future."
}

export { introBannerData };
