import React, { } from 'react'

import YoutubeEmbed from '../../atoms/youtube-embed/youtube-embed'

import './modal-video.scss'

function ModalVideo(props) {


	if (props.isOpen !== true) {
		return null
	}

	return (
		<div className={props.isOpen ? 'modal show' : 'modal hide'}>

			<div className="modal__content">

				<h2 className="modal__title">
					{props.gallery.title}
				</h2>

				<div className="modal__media-gallery">

                    <YoutubeEmbed embedId={props.gallery.embedId} />

				</div>

				<p className="modal__description">
					{props.gallery.description}
				</p>

			</div>

			<div className="modal__close">

				<button onClick={(e) => props.onClose(e)}></button>
				
			</div>
			
		</div>
	)
}

export default ModalVideo
