import React, { useState, useEffect } from 'react'

import { investmentAreasData, investmentStagesData, investmentOutcomesData, } from '../../../data/home/infographic'

import Intro from '../../atoms/intro/intro'
import ScrollIcon from '../../atoms/scroll-icon/scroll-icon'
import NavButton from '../../atoms/nav-button/nav-button'
import SlideCounter from '../../atoms/slide-counter/slide-counter'
import Image from '../../atoms/image/image'

import InvestmentAreas from './investment-areas'
import InvestmentStages from './investment-stages'
import InvestmentSlideOne from './investment-slide-one'
import InvestmentSlideTwo from './investment-slide-two'
import InvestmentSlideThree from './investment-slide-three'
import InvestmentSlideFour from './investment-slide-four'

import './infographic.scss'



export default function Infographic(props) {
  useEffect(() => {

    let movement = 0;
    const scroller = document.querySelector('.infographic__scroll');
    scroller.style.cursor='pointer';
    let scrollerInner = document.querySelector('.infographic__areas');
    let parentScrollerContainer = document.querySelector('.infographic__areas-scroll').getBoundingClientRect();

    scroller.addEventListener('click', function (e) {

      let nav_button = e.target;
      let lastElement = document.querySelector('.infographic__areas-item:last-child').getBoundingClientRect();
      let firstElement = document.querySelector('.infographic__areas-item:first-child').getBoundingClientRect();

      if (nav_button.classList.contains('infographic__scroll-indicator--next') && parentScrollerContainer.right < lastElement.right) {
        movement -= 100;
        scrollerInner.style.marginLeft = `${movement}px`;
      }

      if (nav_button.classList.contains('infographic__scroll-indicator--prev') && firstElement.left < parentScrollerContainer.left) {
        movement += 100;
        scrollerInner.style.marginLeft = `${movement}px`;
      }

    });

  }, [])

  const [investArea, setInvestArea] = useState('national')

  const [investStage, setInvestStage] = useState('early-childhood-development')

  const [currentSlide, setCurrentSlide] = useState(0)

  const transformStr = (val) => val.replace(/\s+/g, '-').toLowerCase()

  const investAreaSelectHandler = (val) => setInvestArea(transformStr(val))

  const investStageSelectHandler = (val) => setInvestStage(transformStr(val))

  const nextSlide = () => {
    currentSlide === 3 ? setCurrentSlide(0) : setCurrentSlide(currentSlide + 1)
  }

  const prevSlide = () => {
    currentSlide === 0 ? setCurrentSlide(3) : setCurrentSlide(currentSlide - 1)
  }

  const slideSelectHandler = (val) => {
    setCurrentSlide(val)
  }

  const activeArea = investmentOutcomesData[investArea][investStage]

  const areaLabel = investmentAreasData.filter((area) => investArea === transformStr(area))

  const stageLabel = investmentStagesData.filter((stage) => investStage === transformStr(stage))



  const {
    title,
    description
  } = props;

  return (

    <div className="content infographic">

      <Intro
        title={title}
        description={description}>
      </Intro>

      <div className="infographic__scroll">

        <div className="infographic__scroll-indicator infographic__scroll-indicator--prev"></div>

        <div className="infographic__areas-scroll">

          <div className="infographic__areas">

            <InvestmentAreas
              areas={investmentAreasData}
              active={investArea}
              investAreaSelect={investAreaSelectHandler}
            />

          </div>

        </div>

        <div className="infographic__scroll-indicator infographic__scroll-indicator--next"></div>

      </div>

      <div className="infographic__section infographic__section--top">

        <div className="infographic__instruction">

          <ScrollIcon />

          <p>
            Click on the different regions above then on the circles to the right to see our investment impact in those locations and focus areas.
					</p>

        </div>

        <div className="infographic__stages">

          <InvestmentStages
            stages={investmentStagesData}
            active={transformStr(investStage)}
            investStageSelect={investStageSelectHandler}
          />

        </div>

      </div>

      <div className="infographic__section infographic__section--bottom">

        <div className="infographic__quad">

          <div
            className={`
                            infographic__quad-item infographic__quad-item--1 
                            ${currentSlide + 1 === 1 ? 'infographic__quad-item--active' : ''}
                        `}
            onClick={() => slideSelectHandler(0)}>

            <div className="infographic__quad-item-image">


              {investStage === "early-childhood-development" ? (

                <Image
                  src="assets/images/infographic/infographic-organisation-early-childhood.png"
                  alt="Organisations"></Image>

              ) : null}

              {investStage === "schooling" ? (

                <Image
                  src="assets/images/infographic/infographic-organisation-schooling.png"
                  alt="Organisations"></Image>

              ) : null}

              {investStage === "post-schooling" ? (

                <Image
                  src="assets/images/infographic/infographic-organisation-post-school.png"
                  alt="Organisations"></Image>

              ) : null}

            </div>

            <div className="infographic__quad-item-number">01</div>

          </div>

          <div className={`
                        infographic__quad-item infographic__quad-item--2
                        ${currentSlide + 1 === 2 ? 'infographic__quad-item--active' : ''}
                        `}
            onClick={() => slideSelectHandler(1)}>

            <div className="infographic__quad-item-image">

              <Image
                src="assets/images/infographic/infographic-overall-approved.png"
                alt="Overall Amount Approved">
              </Image>

            </div>

            <div className="infographic__quad-item-number">02</div>

          </div>

          <div className={`
                        infographic__quad-item infographic__quad-item--3
                        ${currentSlide + 1 === 3 ? 'infographic__quad-item--active' : ''}
                        `}
            onClick={() => slideSelectHandler(2)}>

            <div className="infographic__quad-item-image">

              <Image
                src="assets/images/infographic/infographic-overall-invested.png"
                alt="Overall Amount Invested">
              </Image>

            </div>

            <div className="infographic__quad-item-number">03</div>

          </div>

          <div className={`
                        infographic__quad-item infographic__quad-item--4
                        ${currentSlide + 1 === 4 ? 'infographic__quad-item--active' : ''}
                    `}
            onClick={() => slideSelectHandler(3)}>

            <div className="infographic__quad-item-image">

              <Image
                src="assets/images/infographic/infographic-period.png"
                alt="Investement Period">
              </Image>

            </div>

            <div className="infographic__quad-item-number">04</div>

          </div>

        </div>

        <div className="infographic__slides">

          <div className="infographic__slide-item">

            <div className="infographic__slide-header">

              <div className="infographic__slide-number">
                0{currentSlide + 1}
              </div>

              <div className="infographic__slide-title">

                <h2>{areaLabel}</h2>

                <h3>{stageLabel}</h3>

              </div>

            </div>

            {currentSlide === 0 ? (
              <InvestmentSlideOne {...activeArea[currentSlide]} />
            ) : null}

            {currentSlide === 1 ? (
              <InvestmentSlideTwo {...activeArea[currentSlide]} />
            ) : null}

            {currentSlide === 2 ? (
              <InvestmentSlideThree {...activeArea[currentSlide]} />
            ) : null}

            {currentSlide === 3 ? (
              <InvestmentSlideFour {...activeArea[currentSlide]} />
            ) : null}

          </div>

          <div className="infographic__nav">

            <div className="infographic__nav-prev"
              onClick={prevSlide}>
              <NavButton modifier="prev" />
            </div>

            <div className="infographic__nav-counter">
              <SlideCounter start={currentSlide + 1} end="4" />
            </div>

            <div className="infographic__nav-next"
              onClick={nextSlide}>
              <NavButton modifier="next" />
            </div>

          </div>

        </div>

      </div>

    </div>

  )
}
