
import React, { useState } from 'react'

import TabCard from '../../molecules/tab-card/tab-card'

import './tabs.scss'

function Tabs (props) {

	const {
		tabs
	} = props;

	const [tabState, setTabState] = useState(0)
	const labels = tabs.map((tab) => tab.title).flat(1)

	return (

		<div className="content tabs">

			<div className="tabs__scroll">

				<ul className="tabs__nav">

					{labels.map((label, index) => (

						<li className={`tabs__nav-item tabs__nav-item--${labels[tabState]===label ? 'active' : null}`}
							key={index}
							onClick={() => setTabState(index)}>

								{label}
							
						</li>

					))}

				</ul>
				
			</div>
			

			<TabCard 
				content={tabs[tabState]}>
			</TabCard>

		</div>

	)
}

export default Tabs
