
const infographicHeading = {
	title: "Impact",	
}

const investmentAreasData = [
	'National',
	'Gauteng',
	'Western Cape',
	'Eastern Cape',
	'KwaZulu-Natal',
	'Free State',
	'North West',
	'Limpopo',
	'Mpumalanga',
]

const investmentStagesData = [
	'Early Childhood Development',
	'Schooling',
	'Post Schooling',
]
// function to work out percentage
const percentFunc = function(a,b){
	return ((b/a)*100).toFixed(1);
}

const investmentOutcomesData = {
	national: {
		'early-childhood-development': [
			{
				organisations: [
					'Children’s Institute, University of Cape Town',
					'DG Murray Trust',
					'NECDA',
					'Data Innovators',
					'Grow Great'
				],
			},
			{
				amount: 12560330,
			},
			{
				amount: 11560330,
				
				percentage:  percentFunc(12560330, 11560330),
				
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 570000 },
					{ name: '2018', val: 1075000 },
					{ name: '2019', val: 575000 },
					{ name: '2020', val: 8765330 },
					{ name: '2021', val: 575000 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
		schooling: [
			{
				organisations: [
					'Allan Gray Orbis Foundation',
				],
			},
			{
				amount: 34557521.76,
			},
			{
				amount: 25510730.76,
				percentage:  percentFunc(34557521.76, 25510730.76),
			},
			{
				graphData: [
					{ name: '2016', val: 884480.01 },
					{ name: '2017', val: 2759292.94 },
					{ name: '2018', val: 3298191.40 },
					{ name: '2019', val: 5225983.41 },
					{ name: '2020', val: 6733900 },
					{ name: '2021', val: 6608883 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
		'post-schooling': [
			{
				organisations: [
					'Centre For Development and Enterprice NPC',
					'The Feenix Trust',
					
				],
			},
			{
				amount: 2580536,
			},
			{
				amount: 2580536,
				percentage:  percentFunc(2580536, 2580536),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 0 },
					{ name: '2019', val: 770643 },
					{ name: '2020', val: 412893 },
					{ name: '2021', val: 1397000 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
	},
	'gauteng': {
		'early-childhood-development': [
			{
				organisations: [
					'The Ububele Education and Psychotherapy Foundation NPC',
					
				],
			},
			{
				amount: 5510000,
			},
			{
				amount: 3510000,
				percentage:  percentFunc(5510000, 3510000),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 0 },
					{ name: '2019', val: 1000000 },
					{ name: '2020', val: 1510000 },
					{ name: '2021', val: 1000000 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],

		schooling: [
			{
				organisations: [
					'Bridge Innovation in Learning Organisation NPC',
					"Columba Leadership Trust",
					'Jet Education Services',
					'Junior Achievement South Africa',
				],
			},
			{
				amount: 14355187,
			},
			{
				amount: 9426052.79,
				percentage:  percentFunc(14355187, 9426052.79),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 1743750 },
					{ name: '2019', val: 2085067 },
					{ name: '2020', val: 2293141 },
					{ name: '2021', val: 3304094.79 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
		'post-schooling': [
			{
				organisations: [
					'Artisan Training Institute',
					'Bonds4Jobs',
					'Centre For Development and Enterprice NPC',
					'University of Johannesburg (CSDA)'
				],
			},
			{
				amount: 16903777.50,
			},
			{
				amount: 16903778.08 ,
				percentage:  percentFunc(16903777, 16903778.08),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 1026000 },
					{ name: '2018', val: 4577391.50 },
					{ name: '2019', val: 5667564.50 },
					{ name: '2020', val: 2026158.06 },
					{ name: '2021', val: 3606664 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
	},
	'western-cape': {
		'early-childhood-development': [
			{
				organisations: [
					'Impact Bond Innovation NPC',
					
				],
			},
			{
				amount: 3500000,
			},
			{
				amount: 3500000,
				percentage:  percentFunc(3500000, 3500000),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 3500000 },
					{ name: '2019', val: 0 },
					{ name: '2020', val: 0 },
					{ name: '2021', val: 0 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
		schooling: [
			{
				organisations: [
					'Columba Leadership Trust',
					"Ikamva Lisezandleni Zethu",
				],
			},
			{
				amount: 4291279.67,
			},
			{
				amount: 3691279.67,
				percentage:  percentFunc(4291279.67, 3691279.67),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 1091840 },
					{ name: '2019', val: 1140268.67 },
					{ name: '2020', val: 1059171 },
					{ name: '2021', val: 400000 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
		'post-schooling': [
			{
				organisations: [
					'Bonds4Jobs',
				],
			},
			{
				amount: 6525000,
			},
			{
				amount: 6525000,
				percentage:  percentFunc(6525000, 6525000),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 2775000 },
					{ name: '2019', val: 3750000 },
					{ name: '2020', val: 0 },
					{ name: '2021', val: 0 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
	},
	'kwazulu-natal': {
		'early-childhood-development': [
			{
				organisations: [
					'Bright Kid Foundation',
					'Ntataise'
				],
			},
			{
				amount: 2553160,
			},
			{
				amount: 2553160,
				percentage:  percentFunc(2553160, 2553160),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 1184200 },
					{ name: '2019', val: 0 },
					{ name: '2020', val: 1368960 },
					{ name: '2021', val: 0 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],

		schooling: [
			{
				organisations: [
					'Columba Leadership Trust',
					'Ikamva Lisezandleni Zethu',
				],
			},
			{
				amount: 4291279.67,
			},
			{
				amount: 3691279.67,
				percentage:  percentFunc(4291279.67, 3691279.67),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 1091840 },
					{ name: '2019', val: 1140268.67 },
					{ name: '2020', val: 1059171 },
					{ name: '2021', val: 400000 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
		'post-schooling': [
			{
				organisations: [
					'Artisan Training Institute',
					"Bonds4Jobs",
				],
			},
			{
				amount: 12350391,
			},
			{
				amount: 12350391.96,
				percentage:  percentFunc(12350391, 12350391.96),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 1026000 },
					{ name: '2018', val: 3872100 },
					{ name: '2019', val: 4912926 },
					{ name: '2020', val: 1232701.56 },
					{ name: '2021', val: 1306664 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
				],
			},
		],
	},
	'eastern-cape': {
		'early-childhood-development': [
			{
				organisations: [
					'Bright Kid Foundation',
					'Ntataise '
				],
			},
			{
				amount: 2585640,
			},
			{
				amount: 2585640,
				percentage:  percentFunc(2585640, 2585640),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 1210600 },
					{ name: '2019', val: 1210600 },
					{ name: '2020', val: 164440 },
					{ name: '2021', val: 0 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
		schooling: [
			{
				organisations: [
					'Columba Leadership Trust',
					"Ikamva Lisezandleni Zethu",
					'Rhodes University',
				],
			},
			{
				amount: 10707279.67,
			},
			{
				amount: 10107279.67,
				percentage:  percentFunc(10707279.67, 10107279.67),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 1091840 },
					{ name: '2019', val: 3442668.67 },
					{ name: '2020', val: 1059171 },
					{ name: '2021', val: 4513600 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
		'post-schooling': [
			{
				organisations: [
					'Bonds4Jobs',
				],
			},
			{
				amount: 6525000,
			},
			{
				amount: 6525000,
				percentage:  percentFunc(6525000, 6525000),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 2775000 },
					{ name: '2019', val: 3750000 },
					{ name: '2020', val: 0 },
					{ name: '2021', val: 0 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
	},
	'free-state': {
		'early-childhood-development': [
			{
				organisations: [
					'Bright Kid Foundation',
				],
			},
			{
				amount: 2505160,
			},
			{
				amount: 2505160,
				percentage:  percentFunc(3689360, 2505160),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 1166600 },
					{ name: '2019', val: 0 },
					{ name: '2020', val: 1338560 },
					{ name: '2021', val: 0 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
		schooling: [
			{
				organisations: [
					'Columba Leadership Trust',
				],
			},
			{
				amount: 2000000,
			},
			{
				amount: 1400000,
				percentage:  percentFunc(2000000, 1400000),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 400000 },
					{ name: '2019', val: 400000 },
					{ name: '2020', val: 200000 },
					{ name: '2021', val: 400000 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
		'post-schooling': [
			{
				organisations: [
					'N/A',
				],
			},
			{
				amount: 0,
			},
			{
				amount: 0,
                percentage:  0,
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 0 },
					{ name: '2019', val: 0 },
					{ name: '2020', val: 0 },
					{ name: '2021', val: 0 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
	},
	'north-west': {
		'early-childhood-development': [
			{
				organisations: [
					'Bright Kid Foundation',
					'Ntataise '
				],
			},
			{
				amount: 1274260,
			},
			{
				amount: 1274260,
				percentage:  percentFunc(1274260, 1274260),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 0 },
					{ name: '2019', val: 1184200 },
					{ name: '2020', val: 90060 },
					{ name: '2021', val: 0 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
		schooling: [
			{
				organisations: [
					'N/A',
				],
			},
			{
				amount: 0,
			},
			{
				amount: 0,
				percentage:  0,
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 0 },
					{ name: '2019', val: 0 },
					{ name: '2020', val: 0 },
					{ name: '2021', val: 0 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
		'post-schooling': [
			{
				organisations: [
					'N/A',
				],
			},
			{
				amount: 0,
			},
			{
				amount: 0,
				percentage: 0,
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 0 },
					{ name: '2019', val: 0 },
					{ name: '2020', val: 0 },
					{ name: '2021', val: 0 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
	},
	'limpopo': {
		'early-childhood-development': [
			{
				organisations: [
					'Bright Kid Foundation',
					'Ntataise '
				],
			},
			{
				amount: 2557320,
			},
			{
				amount: 2548120,
				percentage:  percentFunc(2557320, 2548120),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 0 },
					{ name: '2019', val: 1175000 },
					{ name: '2020', val: 1373120 },
					{ name: '2021', val: 0 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
		schooling: [
			{
				organisations: [
					'N/A',
				],
			},
			{
				amount: 0,
			},
			{
				amount: 0,
				percentage: 0,
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 0 },
					{ name: '2019', val: 0 },
					{ name: '2020', val: 0 },
					{ name: '2021', val: 0 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
		'post-schooling': [
			{
				organisations: [
					'N/A',
				],
			},
			{
				amount: 0,
			},
			{
				amount: 0,
				percentage:  0,
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 0 },
					{ name: '2019', val: 0 },
					{ name: '2020', val: 0 },
					{ name: '2021', val: 0 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
	},
	'mpumalanga' : {
		'early-childhood-development': [
			{
				organisations: [
					'N/A',
					
				],
			},
			{
				amount: 0,
			},
			{
				amount: 0,
				percentage:  0,
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 0 },
					{ name: '2019', val: 0 },
					{ name: '2020', val: 0 },
					{ name: '2021', val: 0 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],

		schooling: [
			{
				organisations: [
					'N/A',
					
				],
			},
			{
				amount: 0,
			},
			{
				amount: 0,
				percentage: 0,
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 0 },
					{ name: '2019', val: 0 },
					{ name: '2020', val: 0 },
					{ name: '2021', val: 0 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
		'post-schooling': [
			{
				organisations: [
					'Centre For Development and Enterprice NPC',
				],
			},
			{
				amount: 2253386.50,
			},
			{
				amount: 2253386.50 ,
				percentage: percentFunc(2253386.50, 2253386.50),
			},
			{
				graphData: [
					{ name: '2016', val: 0 },
					{ name: '2017', val: 0 },
					{ name: '2018', val: 705291.50 },
					{ name: '2019', val: 754638.50 },
					{ name: '2020', val: 793456.50 },
					{ name: '2021', val: 0 },
					{ name: '2022', val: 0 },
					{ name: '2023', val: 0 },
					{ name: '2024', val: 0 },
				],
			},
		],
	},
	
}


export {
	infographicHeading,
	investmentAreasData,
	investmentStagesData,
	investmentOutcomesData,
}
