
import React from 'react';

import './icon.scss';

const Icon = (props) => {

	const {
		src,
		alt,
		url,
		target
	} = props;

	return (

		<a href={url}
			target={target}>

			<img src={src}
				className="icon"
				alt={alt}  />

		</a>
		

	)

}

export default Icon;
