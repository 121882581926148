
import React from 'react';

import './scroll-icon.scss';

const ScrollIcon = () => {

	return (

		<div className="scroll-icon">
			
			<div className="scroll-icon__mouse">

				<div className="scroll-icon__scroller"></div>

			</div>

		</div>
        
	)

}

export default ScrollIcon;
