
import React from 'react';

import './image.scss';

const Image = (props) => {

	const {
		alt,
		src
	} = props;

	return (

		<img src={src}
			className="image"
			alt={alt} />
        
	)

}

export default Image;
