const articlesData = {
	title: "Tutuwa & Partners",
	description: [
		"A range of news articles relating to either our work and that of our partners or our sector as a whole.",
	],
	articles: [
		{
			image: {
				type: "",
				src: "/assets/images/media/articles/article-malnutrition.jpg",
				alt: "Malnutrition: ‘A slow violence against our children’",
			},
			title: "Malnutrition: ‘A slow violence against our children’",
			description: "Dr Tshepo Motsepe’s keynote address at the launch of the South African Child Gauge 2020 focussed on food safety.",
			link: {
				modifier: "",
				target: "_blank",
				text: "Read more",
				url: "https://www.dailymaverick.co.za/article/2021-02-18-malnutrition-hurts-millions-of-south-africas-children-just-as-it-did-20-years-ago/",
			},
		},
		{
			image: {
				type: "",
				src: "/assets/images/media/articles/article-matric-results-unequal.jpg",
				alt: "Matric results highlight unequal schooling in SA",
			},
			title: "Matric results highlight unequal schooling in SA",
			description: "An opinion piece by Prof. Michael le Cordeur, head of Stellenbosch University’s Department of Curriculum Studies and ambassador of the Rapport Bursary Fund.",
			link: {
				modifier: "",
				target: "_blank",
				text: "Read more",
				url: "https://www.dailymaverick.co.za/opinionista/2021-02-28-matric-results-highlight-unequal-schooling-in-south-africa-and-the-dedication-of-our-teaching-corps/",
			},
		},
		{
			image: {
				type: "",
				src: "/assets/images/media/articles/article-early-childhood.jpg",
				alt: "Early childhood development starts with parents",
			},
			title: "Early childhood development starts with parents",
			description: "An opinion piece by Kaathima Ebrahim, chief executive officer of Mikhulu Child Development Trust.",
			link: {
				modifier: "",
				target: "_blank",
				text: "Read more",
				url: "https://www.iol.co.za/capeargus/opinion/early-childhood-development-starts-with-parents-and-government-policy-needs-to-reflect-this-15bcca3c-fc9f-44b4-b6c6-baf90f87e6a7",
			},
		},
		{
			image: {
				type: "",
				src: "/assets/images/media/articles/article-partnering-safegaurd.jpg",
				alt: "Partnering to safeguard young children",
			},
			title: "Partnering to safeguard young children",
			description: "A look at the effect of COVID-19 on the Early Childhood Development (ECD) sector by our Foundation’s CEO, Zanele Twala.",
			link: {
				modifier: "",
				target: "_blank",
				text: "Read more",
				url: "http://ipa-sa.org.za/newsletter/partnering-to-safeguard-young-children-and-build-a-better-future/",
			},
		},
		{
			image: {
				type: "",
				src: "/assets/images/media/articles/article-covid19.jpg",
				alt: "COVID-19 and ECD",
			},
			title: "COVID-19 and ECD",
			description: "“COVID-19 has devastated early childhood development programmes, but we’re slowly clawing our way back,” writes Laura Brooks and Andrew Hartnack",
			link: {
				modifier: "",
				target: "_blank",
				text: "Read more",
				url: "https://www.dailymaverick.co.za/article/2020-11-05-covid-19-has-devastated-early-childhood-development-programmes-but-were-slowly-clawing-our-way-back/",
			},
		},
		{
			image: {
				type: "",
				src: "/assets/images/media/articles/article-neets.jpg",
				alt: "A net to catch the NEETs",
			},
			title: "A net to catch the NEETs",
			description: "The number of 15- to 34-year-olds who are disengaged from both work and education presents a crisis, writes Amuzweni Ngoma.",
			link: {
				modifier: "",
				target: "_blank",
				text: "Read more",
				url: "https://mg.co.za/opinion/2020-08-21-a-net-to-catch-the-neets/",
			},
		},
	]
};

export { articlesData };
