

import React from "react";

import Image from '../../atoms/image/image';

import "./info-card.scss";

const InfoCard = (props) => {

	const {
		content
	} = props;

	return (

		<div className="info-card">

			<div className="info-card__image">

				<Image 
					src={content.image.src}
					alt={content.image.alt}>
				</Image>

			</div>

			<div className="info-card__content">

				<div>

					<h4>
						{content.name}
					</h4>

					<p>
						{content.title}
					</p>
					
				</div>

			</div>
			

		</div>
        
	);
};

export default InfoCard;
