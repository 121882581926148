
const introBannerData = {
	background: {
		desktop: "/assets/images/intro-banner/banner-programmes.jpg",
		mobile: "",
		alt: "Our Programmes",
	},
	title: "Our Programmes",
	description: "Harnessing the potential of South Africa’s youth",
	button: {
		target: "_self",
		text: "Our partners",
		url: "#our-partners",
	},
}

export { introBannerData };
