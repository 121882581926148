
import React from 'react';

import './responsive-image.scss';

const ResponsiveImage = (props) => {

	const {
		desktop,
		mobile,
		alt
	} = props;

	return (

		<picture>

			<source srcSet={desktop} 
				media="(min-width: 768px)" />

			<img src={mobile}  
				alt={alt}/>
			
		</picture>
        
	)

}

export default ResponsiveImage;
