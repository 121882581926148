
const reportsData = {
	title: "Reports",
	description: [
		"Download and read a range of documents providing detailed information on the work we do, the work of our partners and matters relevant to our sector as a whole.",
	],
	reports: [
		{
			title: "Tutuwa Reports",
			item: [
				{
					title: "Tutuwa Strategy Plan 2021–2025",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "/assets/downloads/2021-2025_Tutuwa_Strategy_Plan_Final_interactive_short_version.pdf",
					}
				},
				{
					title: "Tutuwa Strategy Plan 2017–2020",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "/assets/downloads/Tutuwa-Community-Strategy-2017-2020.pdf",
					}
				},
				{
					title: "Standard Bank Tutuwa Community Foundation Brochure",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "/assets/downloads/Tututwa-Brochure.pdf",
					}
				},
				{
					title: "Scholars Graduation Class of 2020",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "/assets/downloads/Tutuwa-Foundation_Booklet_Final.pdf",
					}
				},
				{
					title: "Tutuwa Impact Report 2019",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "/assets/downloads/Tutuwa-Impact-Report-LR-Spreads.pdf",
					}
				},
				{
					title: "Tutuwa Review Report 2016–2018",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "/assets/downloads/Tutuwa-Review-Report-2016-2018.pdf",
					}
				},
				{
					title: "Intellidex SIBs in SA Report 2021",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "/assets/downloads/Intellidex-SIBs-in-SA-Report-2021.pdf",
					}
				},
				{
					title: "CDE NEETs Report 2021 ",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "/assets/downloads/CDE-NEETs-Report-2021.pdf",
					}
				},
				{
					title: "Scholars Graduation Class of 2021",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "/assets/downloads/Graduation-Ceremony-2021-GMS-19685-Tutuwa-Booklet-DIGITAL.pdf",
					}
				},
				{
					title: "Intellidex Bonds4Jobs Social Impact Bond 2021",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "/assets/downloads/Intellidex-the-B4J-SIB-report.pdf",
					}
				},
				{
					title: "Intellidex Impact Bond Innovation Fund 2021",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "/assets/downloads/Intellidex-the-IBIF-SIB-report.pdf",
					}
				},
			]
		},
		{
			title: "Partner Reports",
			item: [
				{
					title: "Child Gauge 2020",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "/assets/downloads/ChildGauge_2020_screen_final.pdf",
					}
				},
				{
					title: "Child Gauge 2019",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "/assets/downloads/South-African-Child-Gauge-2019.pdf",
					}
				},
				{
					title: "Child Gauge 2018",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "/assets/downloads/South-African-Child-Gauge-2018.pdf",
					}
				},
				{
					title: "Child Gauge 2017",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "/assets/downloads/South-African-Child-Gauge-2017.pdf",
					}
				},
			]
		},
		{
			title: "Sector reports",
			item: [
				{
					title: "Intellidex Understanding Empowerment Endowments Report 2018 ",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "/assets/downloads/Understanding-Empowerment-Endowments.pdf",
					}
				},
				{
					title: "Fact Sheet on “NEETs” 2021 ",
					link: {
						modifier: "download",
						target: "_blank",
						text: "Download",
						url: "https://www.dhet.gov.za/Planning%20Monitoring%20and%20Evaluation%20Coordination/Fact%20Sheet%20on%20NEET%20-%202021.pdf",
					}
				},
			]
		},
	]
}

export { reportsData };
