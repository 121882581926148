import React from "react";
import { NavLink } from "react-router-dom";

import Helmet from "react-helmet";

class SiteMap extends React.Component {

    componentDidUpdate(){
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    render() {

        return (

            <div className="site-map">

                <Helmet>
                    <title>The Standard Bank Tutuwa Community Foundation: Site Map </title>
                    <meta name="title" content="The Standard Bank Tutuwa Community Foundation: Site Map "/>
                    <meta name="description" content="We aim to help young South Africans reach their full potential and make an active contribution to their community."/>
                    <meta name="keywords" content="Standard bank; tutuwa foundation; South African youth; investing in South Africa’s youth; social impact initiatives; economic development; investing in social impact bonds; impact investing; thought leadership; economic growth and development; industrial development; department of trade, industry and competition; youth empowerment; black economic empowerment"/>
                    <meta name="robots" content="index, follow"/>
                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                    <meta name="language" content="English"/>
                </Helmet>

				<div className="content-wrapper">

					<div className="content">

						<h2>Site Map</h2>

						<p>
							<ul>

								<li>

									<NavLink
										to="/home"
									>
										Home
									</NavLink>

								</li>

								<li>

									<NavLink
										to="/about"
									>
										About Us
									</NavLink>

								</li>

								<li>

									<NavLink
										to="/programmes"
									>
										Our Programmes
									</NavLink>

								</li>

								<li>

									<NavLink
										to="/media-and-news"
									>
										Media & News
									</NavLink>

								</li>

								<li>

									<NavLink
										to="/contact"
									>
										Contact Us
									</NavLink>

								</li>

							</ul>
						</p>

					</div>

				</div>

            </div>

		);
		
	}
	
}

export default SiteMap;
