import React, { useState } from 'react'

import { milestonesData } from '../../../data/about/milestones'

import Intro from '../../atoms/intro/intro'
import ResponsiveImage from '../../atoms/responsive-image/responsive-image'
import Image from '../../atoms/image/image'
import ScrollIcon from '../../atoms/scroll-icon/scroll-icon'
import NavButton from '../../atoms/nav-button/nav-button'
import MilestoneCard from '../../molecules/milestone-card/milestone-card'
import RangeSlider from '../../molecules/range-slider/range-slider'

import './timeline.scss'

export default function Timeline(props) {
	const { 
		title, 
		description, 
		background, 
		image 
	} = props


    const milestones = milestonesData.event.sort();

    const milestoneLength = milestonesData.event.length - 1

	const [selectedYear, setYear] = useState(milestones[milestoneLength].year)

	const [selectedIndex, setIndex] = useState(milestoneLength)

	const [animationState, setAnimationState] = useState('')

	const [milestoneContent, setMilestoneContent] = useState(
		milestones[milestoneLength]
	)

	const selectYearHandler = (val) => {
		setAnimationState('in')
		setYear(milestones[val].year)
		setMilestoneContent(milestones[val])
		setTimeout(() => {
		    setAnimationState('')
		}, 500);
	}

	const prevSlide = () => {
        if(selectedIndex === 0) {
            setIndex(milestoneLength);
            selectYearHandler(milestoneLength);
        }else {
            setIndex(selectedIndex-1);
            selectYearHandler(selectedIndex-1);
        }
	}

	const nextSlide = () => {
        if(selectedIndex === milestoneLength) {
            setIndex(0);
            selectYearHandler(0);
        }else {
            setIndex(selectedIndex+1);
            selectYearHandler(selectedIndex+1);
        }   
	}

	return (

		<div className="timeline">

			<div className="content">

				<Intro 
					title={title} 
					description={description} />

			</div>

			<div className="timeline__wrapper">

				<div className="timeline__background">

					<div className="timeline__background-left">

						<div className="timeline__background-image">

							<ResponsiveImage 
								mobile={background.mobile}
								desktop={background.desktop}
								alt={background.alt} />

						</div>

					</div>

					<div className="timeline__background-right"></div>

				</div>

				<div className="content">

					<div className="timeline__content">

						<div className="timeline__detail">

							<div className="timeline__slider">

								<RangeSlider
                                    milestones={milestones}
									max={milestoneLength}
									selectedIndex={selectedIndex}
									selectYearHandler={selectYearHandler} />
                                    
							</div>

							<div className="timeline__card">

								<div className={`timeline__card-item ${animationState}`}>

									<MilestoneCard {...milestoneContent} />

								</div>

								<div className="timeline__card-nav">

									<div className="timeline__instruction">
										
										<ScrollIcon/>

										<p>
											Select the dates on this timeline for more info on Tutuwa’s milestones. 
										</p>

									</div>

									<div className="timeline__nav">

										<div onClick={prevSlide}>
											<NavButton modifier="prev"/>
										</div>

										<div onClick={nextSlide}>
											<NavButton modifier="next"/>
										</div>

									</div>

								</div>

							</div>

						</div>

						<div className="timeline__image">

							<Image 
								src={image.src} 
								alt={image.alt}>
							</Image>

						</div>

					</div>

				</div>

			</div>

		</div>
	)
}
