
import React from 'react';

import './nav-button.scss';

const NavButton = (props) => {

	const {
		modifier
	} = props;

	return (

		<button className={`nav-button nav-button--${modifier}`}></button>
        
	)

}

export default NavButton;
