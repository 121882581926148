

import React from "react";

import Map from '../../atoms/map/map';
import Link from '../../atoms/link/link';

import "./location.scss";

const Location = (props) => {

	const {
		address,
		email,
		map,
	} = props;

	return (

		<div className="content location">

			<div className="location__map">

				<Map 
					content={map}/>

			</div>

			<div className="location__content">

				<h5>
					Physical Address:
				</h5>

				<div className="location__item">

					<p>
						<span>{address.line1}</span>
						<span>{address.line2}</span>
						<span>{address.line3}</span>
						<span>{address.line4}</span>
					</p>
					
				</div>

				<h5>
					Email Address:
				</h5>

				<div className="location__item">

					<Link 
						modifier={email.modifier}
						target={email.target}
						text={email.text}
						url={email.url}>
					</Link>

				</div>

				

			</div>

		</div>
        
	);
};

export default Location;
