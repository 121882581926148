import React from "react";
import Helmet from "react-helmet";

class Terms extends React.Component {

    componentDidUpdate(){
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    render() {

        return (

            <div className="terms-and-conditions">

                <Helmet>
                    <title>The Standard Bank Tutuwa Community Foundation: Terms and Conditions </title>
                    <meta name="title" content="The Standard Bank Tutuwa Community Foundation: Terms and Conditions "/>
                    <meta name="description" content="We aim to help young South Africans reach their full potential and make an active contribution to their community."/>
                    <meta name="keywords" content="Standard bank; tutuwa foundation; South African youth; investing in South Africa’s youth; social impact initiatives; economic development; investing in social impact bonds; impact investing; thought leadership; economic growth and development; industrial development; department of trade, industry and competition; youth empowerment; black economic empowerment"/>
                    <meta name="robots" content="index, follow"/>
                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                    <meta name="language" content="English"/>
                </Helmet>
				
				<div className="content-wrapper">

					<div className="content">

						<div id="Top"></div>

						<h2>TERMS AND CONDITIONS OF VISITING OUR WEBSITE (TERMS)</h2>

						<p>Thank you for visiting our website. Before you go further, you must read, understand and agree to the terms and conditions (“Terms”) for using the website. These Terms will be binding between you and us regarding your use of our website.</p>

						<p>
							<ul>
								<li>
									<a href="#Acceptance">Acceptance</a>
								</li>
								<li>
									<a href="#Info">Types of information on our website</a>
								</li>
								<li>
									<a href="#Privacy">Data Privacy</a>
								</li>
								<li>
									<a href="#LiveInfo">Live information on the website</a>
								</li>
								<li>
									<a href="#Links">Links to other parties’ websites</a>
								</li>
								<li>
									<a href="#Permissions">Permission for linking to our website</a>
								</li>
								<li>
									<a href="#Intellectual">Our intellectual property</a>
								</li>
								<li>
									<a href="#Hardware">You must use suitable hardware and software</a>
								</li>
								<li>
									<a href="#Risks">Risks of sending information over the Internet</a>
								</li>
								<li>
									<a href="#Closing">Closing, suspending or limiting the website</a>
								</li>
								<li>
									<a href="#Disclamier">Disclaimer and limits on our responsibility</a>
								</li>
								<li>
									<a href="#Disputes">How disputes will be resolved</a>
								</li>
								<li>
									<a href="#Capacity">Capacity to enter into agreements</a>
								</li>
								<li>
									<a href="#Certificatee">Our certificate is initial proof of our website’s contents</a>
								</li>
								<li>
									<a href="#Address">Our address for notices and service of legal process</a>
								</li>
								<li>
									<a href="#Law">The law that applies to these Terms</a>
								</li>
								<li>
									<a href="#General">General</a>
								</li>
							</ul>
						</p>

						<div id="Acceptance">

							<h4>Acceptance</h4>

							<p>These Terms apply when you visit the website for the first time and are a binding agreement between us (The Standard Bank Tutuwa Community Foundation NPC) and you. We may change these Terms from time to time, and the latest version of these Terms will then apply each time you visit this website. Our website includes all related pages and websites.</p>

							<p>A certificate (letter) signed by the administrator of this website is prima facie (initial) proof of the date and content of the latest and any previous version of these Terms.</p>
						
							<p>
								<a href="#Top">Return to the top of the page</a>
							</p>
						
						</div>

						<div id="Info">

							<h4>Types of information on our website</h4>

							<p>All information on this site is only intended to give you general information about us, our products, services and goals. You should not regard anything on our website as an offer, nor as an invitation to do business with us.</p>

							<p>For example, we may give information from time to time on our plans, goals or estimates for our operations or services in future.</p>

							<p>
								<a href="#Top">Return to the top of the page</a>
							</p>
						
						</div>

						<div id="Privacy">

							<h4>Data privacy</h4>

							<p>We take your privacy of your personal information very seriously. You can find out more about this in our privacy statement.</p>

							<p>
								<a href="#Top">Return to the top of the page</a>
							</p>
						
						</div>

						<div id="LiveInfo">

							<h4>Live information on the website</h4>

							<p>We may use other organisations to provide information on this website. We have no control over this information and do not warrant or guarantee that it is correct or suitable for anything.  We are not directly or indirectly responsible for any loss that may follow from you relying on it.</p>

							<p>All current news, market information or data shown on our website as live information is delayed by at least 15 minutes unless we tell you otherwise. You should always update or "refresh" the screen on any device to make sure that you are looking at the latest information.</p>

							<p>
								<a href="#Top">Return to the top of the page</a>
							</p>
						
						</div>

						<div id="Links">

							<h4>Links to other websites</h4>

							<p>The website may link to other websites with information or content from other parties. Although we try to only link to trustworthy parties or systems, we are not responsible for any of this other information. If we provide a link to any other information, website, person or business, it does not mean that we have checked or approve of them.</p>

							<p>We do not give any warranty about any other website, software or hardware, including their security or performance. You waive (give up) any claim you may have against us for any loss or damage you may suffer because you connect to any other website.</p>

							<p>
								<a href="#Top">Return to the top of the page</a>
							</p>
						
						</div>

						<div id="Permissions">

							<h4>Permission for linking to our website</h4>

							<p>Nobody may create any kind of link or reference to our website without first getting our written permission. We may refuse permission or grant it on certain conditions. Links that need permission include hyperlinks (automatic links in text), deep links (that bypass the website’s home page), frames, metatags or any similar reference, electronic or not.</p>

							<p>Any request for linking to our website must be sent to webmaster@standardbank.co.za. We will try to answer as soon as possible. If we do not respond in writing in five business days, we have not agreed to your request.</p>

							<p>If you breach these Terms, we may take immediate legal action against you, and you could be held responsible for the legal costs of such action.</p>

							<p>
								<a href="#Top">Return to the top of the page</a>
							</p>
						
						</div>

						<div id="Intellectual">

							<h3>Our intellectual property</h3>
							
							<h4>Intellectual property rights</h4>

							<p>We own all intellectual property rights in our website and all content published on or via our website, including but not limited to all proprietary information and trademarks on the website; and copyright therein. You may view content and download one copy of it onto a computer or other device or storage media, and you may print and make paper copies of it, but only if:</p>

							<p>

								<ul>

									<li>it is not used for any commercial purposes; and</li>

									<li>any copy of the content or portion of it from any part of our website shows our copyright notice</li>

								</ul>

							</p>

							<p>The logos, devices and word marks on our website are our registered and unregistered trademarks or those of other parties. Nothing on our website is a licence (permission) or right to use any trademark or any other intellectual property for any other purpose.</p>

							<p>You may not, without our prior written permission, use our intellectual property or that of a third party.</p>

							<p>You may not establish a hyperlink, frame, metatag or similar reference, whether electronically or otherwise to the website or any subsidiary pages without our prior written consent, which consent is at our sole discretion.</p>

							<p>Even if any content on our website is not confidential or there is no copyright in it, we own all of it and you have no rights in it.</p>

							<p>
								<a href="#Top">Return to the top of the page</a>
							</p>
						
						</div>

						<div id="Hardware">

							<h4>You must use suitable hardware and software</h4>

							<p>You must use and update hardware and software (computer equipment and programs) suitable for this website. If you do not, the website may not work properly and this could increase your security risks. If we offer any software to you on or through our website, the licence agreement is between you and the software licensor (owner).</p>

							<p><em>You indemnify us against any breach of a software licence. We do not expressly or implicitly warrant that any software is of good quality or suitable for its purpose.</em></p>

							<p><em>We also do not warrant that any file, download or application available on the communication system is safe to use on any computer. We cannot guarantee that it contains no software or data that can negatively affect a computer system, such as viruses, trojans and other malicious software.</em></p>

							<p>
								<a href="#Top">Return to the top of the page</a>
							</p>
						
						</div>

						<div id="Risks">

							<h4>Risks of sending information over the Internet</h4>

							<p><em>Information sent over the Internet, including email, can be intercepted, seen or changed. We take steps to limit these risks as explained in our privacy statement. Because we cannot stop all illegal activities, we are not responsible for any loss or damage you may suffer as a result. To limit risks, we may verify any information you send using our website or email.</em></p>

							<p>
								<a href="#Top">Return to the top of the page</a>
							</p>
						
						</div>

						<div id="Closing">

							<h4>Closing, suspending or limiting the website</h4>

							<p>We may change, suspend or close the website temporarily or permanently without giving notice. We may also limit certain services, features or functions, and we may restrict access to all or parts of any service on the website</p>

							<p>
								<a href="#Top">Return to the top of the page</a>
							</p>
						
						</div>

						<div id="Disclaimer">

							<h4>Disclaimer and limits of our responsibility</h4>

							<p><em>Your use of this website is dependent on factors beyond our control, such as the network coverage or availability of your Internet Service Provider’s availability. We are not liable for any loss or damages you may suffer if a factor beyond our control arises, and you cannot access this website.</em></p>

							<p><em>You use this website entirely at your own risk. You are fully responsible for any loss or damage that may result from you using or not being able to use this website or any of its content, or information.</em></p>

							<p><em>We (including our owners, agents, consultants, employees and any affiliated person) are not responsible for any loss or damages related to your use of this website. This includes, without limitation, any direct, indirect, special, incidental, consequential or punitive damages in terms of any contract, delict (breach of a duty of care) or law, and even if we were expressly told that any loss or damage was possible.</em></p>

							<p><em>We are not responsible for any loss that results from:</em></p>

							<p>
								<em>
									<ul>
										<li>any technical or other problem (including interruption, malfunction, downtime or other failure) that affects our website, system or any database for any reason;</li>
										<li>any problem affecting any other goods or services provided by any other party, for example any telecommunication or internet service provider, electricity supplier, or local or other authority;</li>
										<li>any loss of or damage to any personal information or other data caused directly or indirectly by technical problems, power failures, unlawful acts (such as data theft), any harmful computer program or virus, or because of your own negligence ; or</li>
										<li>any event over which we have no direct control.</li>
									</ul>
								</em>
							</p>

							<p>
								<a href="#Top">Return to the top of the page</a>
							</p>
						
						</div>

						<div id="Disputes">

							<h4>How disputes will be resolved</h4>

							<p>Any dispute over your use of this website or these Terms will be decided by arbitration, unless either of us applies for an interdict or other urgent legal action. The terms and conditions of any other relevant service will apply if they are different to these Terms.</p>

							<p>The arbitration will be at the offices of the Arbitration Foundation of Southern Africa (AFSA) in Sandton, South Africa, under its rules and South African law.</p>

							<p>Either of us may have the arbitration award made an order of court. Both of us will keep the evidence and any award in the arbitration confidential. The arbitrator may give default judgement if any party fails to make submissions on the date they are due or fail to appear at the arbitration or both.</p>

							<p>
								<a href="#Top">Return to the top of the page</a>
							</p>
						
						</div>

						<div id="Capacity">

							<h4>Capacity to enter into agreements</h4>

							<p>You warrant to us that you are legally capable of agreeing to these Terms. If you are younger than 18, or if you are not sure if you can legally enter into agreements without your legal guardian such as a parent agreeing to it with you, you must find out before you use the website.</p>

							<p>
								<a href="#Top">Return to the top of the page</a>
							</p>
						
						</div>

						<div id="Certificate">

							<h4>Our certificate is initial proof of our website’s contents</h4>

							<p>A letter signed by us of the operation or functionality of any part of the website contents on any date are accepted as correct until proved otherwise.</p>

							<p>
								<a href="#Top">Return to the top of the page</a>
							</p>
						
						</div>

						<div id="Address">

							<h4>Our address for notices and service of legal process</h4>

							<p>We choose our registered address as stated on this website as the physical/street address where any legal document or notice must be served on or delivered to us <em>(our domicilium citandi et executandi)</em>:</p>

							<p>
								Group Governance Office<br />
								9th Floor<br />
								5 Simmonds Street<br />
								Johannesburg<br />
								2001.
							</p>

							<p>
								<a href="#Top"></a>
							</p>
						
						</div>

						<div id="Law">

							<h4>The law that applies to these Terms</h4>

							<p>South African law applies to these Terms, despite any other country’s law.</p>

							<p>
								<a href="#Top"></a>
							</p>
						
						</div>

						<div id="General">

							<h4>General</h4>

							<p>The headings in these Terms are only for information. Do not consider them when you interpret the Terms.</p>

							<p>Any favour we may allow you will not affect any of our rights against you, whether the favour is expressed (said) or implied (not said). We do not waive (give up) any of our rights.</p>
							
							<p>If any clause in these Terms is invalid or illegal or cannot be enforced, the remaining clauses will continue to be valid.</p>

							<p>Email <a href="mailto:webmaster@standardbank.co.za" target="blank">webmaster@standardbank.co.za</a> if you have questions or do not understand anything about these Terms and we will respond as soon as possible.</p>

							<p>
								<a href="#Top"></a>
							</p>
						
						</div>

					</div>

					

				</div>

            </div>

		);
		
	}
	
}

export default Terms;
