
const introData = {
	image: {
		src: "/assets/images/intro-about-us.jpg",
		alt: "Online News Article",
	},
	title: "About us",
	subTitle: "Our Foundation does three things:",
	description: [
		'<ul><li>We provide grants for programmes that are within our approved strategy and selected thematic areas – early childhood development, schooling and post-schooling.</li><li>We invest in blended funding models, including social impact bonds and conditional grants to tackle social issues across South Africa.</li><li>We act as a thought leader and strategic player and are an active, engaged and proactive facilitator in our programme focus areas. This role is also that of an activator, funding what works and promoting the formation and coordination of networks, partnership building and knowledge sharing.</li></ul>',
	],
}

export { introData };
