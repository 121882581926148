
import React from 'react'

import ReportItem from '../report-item/report-item'

import "./report-list.scss";

const ReportList = (props) => {

	const {
		content
	} = props;


	return (

		<div className="report-list">

			{content.item.map((item, index) => {

				return (

					<ReportItem
						key={index} 
						content={item} 
						index={index}>					
					</ReportItem>

				);

			})}

		</div>
	)
}

export default ReportList;
