
const partnersData = {
	title: "Our Partners",
	description: [
		"Find out more about the organisations we’ve specially selected to work with on a variety of programmes, aimed at achieving our shared goal of enabling South Africa’s youth to reach their full potential. ",
	],
	highlights: [
		{
			image: {
				src: "/assets/images/our-partners/partners-ECD-grant.jpg",
				alt: "DG Murray Trust",
			},
			logo: {
				src: "/assets/images/our-partners/logo/logo-DGMT.png",
				alt: "DGMT",
			},
			tag: "ECD Conditional Grant",
			title: "DG Murray Trust",
			description: [
				"The DG Murray Trust is the implementing partner for the ECD Conditional Grant (CG). This is an initiative involving a consortium of donors that are collaborating with one another and with the Department of Social Development (DSD) to improve the implementation of the ECD CG.",
				"Our Foundation joined the consortium as a private sector funder, allowing for the leveraging of its grant funding to support the grant’s implementation. We aim to create a clear financial reporting line between the DSD and the provinces, as well as to develop a platform and systems that will ensure the grants are efficiently properly allocated.",
				"In addition to the Conditional Grant, funding was allocated to provide COVID-19 relief assistance to unregistered ECD sites that are not getting a subsidy from government. The purpose of this funding relief is twofold: to enable these ECD sites to successfully reopen following the COVID-19 lockdown period; and to test effective methods to deliver nutritional support as well as a compliance package that responds to COVID-19 requirements for hygiene practices.",
			],
			link: {
				modifier: "",
				target: "_blank",
				text: "View website",
				url: "https://dgmt.co.za/",
			},
		},
		{
			image: {
				src: "/assets/images/our-partners/partners-ECD-impact-bond.jpg",
				alt: "Volta Capital, mothers2mothers and Foundation for Community Work",
			},
			logo: {
				src: "/assets/images/our-partners/logo/logo-impact-bond.png",
				alt: "FCW, mothers2mothers, Volta",
			},
			tag: "ECD Impact Bond Innovation Fund",
			title: "Volta Capital, mothers2mothers and Foundation for Community Work",
			description: [
				"We have collaborated with Volta Capital, mothers2mothers and Foundation for Community Work in the first ECD impact bond launched in South Africa, with our Foundation joining as one of the bond’s first investors.",
				"The ECD impact bond is an outcomes-based financing mechanism that seeks to improve early child-hood learning and development outcomes. Its collaborative approach aims at ensuring children directly benefit from the funding. The investment also looks to identify delivery models for non-centre based programmes, as well as strengthen organisations with a view to scaling these models.",
			],
			link: {
				modifier: "",
				target: "_blank",
				text: "View website",
				url: "https://voltaimpact.com/",
			},
		},
		{
			image: {
				src: "/assets/images/our-partners/partners-child-gauge.jpg",
				alt: "South African Children’s Institute",
			},
			logo: {
				src: "/assets/images/our-partners/logo/logo-childrens-institute.png",
				alt: "Children's Institute",
			},
			tag: "South African Child Gauge",
			title: "South African Children’s Institute",
			description: [
				"The South African Child Gauge is an annual publication of the UCT Children’s Institute. It aims to report on and monitor the situation of children in South Africa, in particular the realisation of their rights. The publication focuses on a different theme each year.",
				"The initiative aims for a rigorous evidence-base to be created to track progress made towards the realisation of children's rights, to influence broader change and to contribute towards evidence-based policy making. The investment also encourages collaboration with research institutes to allow for the growth and dissemination of this evidence-base. ",
			],
			link: {
				modifier: "",
				target: "_blank",
				text: "View website",
				url: "http://www.ci.uct.ac.za/",
			},
		},
		{
			image: {
				src: "/assets/images/our-partners/partners-delivery-of-edutainers.jpg",
				alt: "Bright Kid Foundation",
			},
			logo: {
				src: "/assets/images/our-partners/logo/logo-bright-kid.png",
				alt: "Bright Kid Foundation",
			},
			tag: "Delivery of Edutainers®",
			title: "Bright Kid Foundation",
			description: [
				"The Bright Kid Foundation delivers ECD Edutainers® to selected preschools with trained educators in rural areas and informal settlements. Each Edutainer® is an ‘instant’ classroom kitted with books, teaching aids and educational toys. The Edutainers®  and infrastructure installations offered by the Bright Kid Foundation in which facilities are added to an ECD centre enable the Department of Social Development norms and standards for registration to be met, allowing an ECD centre to qualify for an ECD subsidy and to charge school fees to ensure sustainability.",
				"Our Foundation’s investment will allow Bright Kid Foundation to provide Edutainers® and infrastructure installations to nine ECD centres over three years and across three provinces. ",
				"In addition to the funding of Edutainers®, we have supported an intervention to provide a tailor-made solution for hand-washing facilities that will improve the conditions at ECD sites. These will dispense soap for mandatory hand-washing practices. This support was to enable the 9 funded ECD centres to align to the new COVID-19 regulations and hygiene practices.",
			],
			link: {
				modifier: "",
				target: "_blank",
				text: "View website",
				url: "https://brightkidfoundation.co.za/",
			},
		},
		{
			image: {
				src: "/assets/images/our-partners/partners-early-childhood.jpg",
				alt: "Ububele",
			},
			logo: {
				src: "/assets/images/our-partners/logo/logo-ububele.png",
				alt: "Ububele",
			},
			tag: "First 1000 Days",
			title: "Ububele",
			description: [
				"Founded in 2000, Ububele focuses on improving the emotional development and wellbeing of children under seven years. The organisation provides a basket of services to infants in their first 1 000 days as well as to their caregivers. Ububele also provides training to healthcare and social service professionals to contribute to educational outcomes of children in their earliest years. ",
				"Our Foundation provides funding allowing Ububele to extend the reach of its basket of services to infants; as well as for training and work experience in infant mental health for 48 healthcare workers.",
				"In addition to supporting the First 1000 Days Programme, we have provided funding to assist Ububele with operational expenses incurred due to the COVID-19 pandemic. ",
			],
			link: {
				modifier: "",
				target: "_blank",
				text: "View website",
				url: "https://ububele.org/",
			},
		},
		{
			image: {
				src: "/assets/images/our-partners/partners-tutuwa-scholarship.jpg",
				alt: "Allan Gray Orbis Foundation",
			},
			logo: {
				src: "/assets/images/our-partners/logo/logo-allan-gray.png",
				alt: "Allan Gray Orbis Foundation",
			},
			tag: "Standard Bank Tutuwa Scholarship Programme",
			title: "Allan Gray Orbis Foundation",
			description: [
				"The Allan Gray Orbis Foundation scholarship programme aims to provide high quality high school education for young people from disadvantaged communities.",
				"We have partnered with this foundation to creating the Standard Bank Tutuwa Scholarship Programme in 2015, extending the reach of their successful programme. Through this partnership, which leverages the extensive selection process and development programme of the Allan Gray Orbis Foundation, we have selected 53 scholars since its inception. ",
			],
			link: {
				modifier: "",
				target: "_blank",
				text: "View website",
				url: "https://www.allangrayorbis.org/",
			},
		},
		{
			image: {
				src: "/assets/images/our-partners/partners-school-leadership.jpg",
				alt: "Tutuwa Bridge School Leadership Programme",
			},
			logo: {
				src: "/assets/images/our-partners/logo/logo-bridge.png",
				alt: "BRIDG",
			},
			tag: "Tutuwa Bridge School Leadership Programme",
			title: "BRIDGE",
			description: [
				"BRIDGE aims to drive collaboration and cooperation among stakeholders in education to increase the collective impact on the system. The School Leadership Programme focuses on school leadership and teacher development, aiming to bring about positive change in participating schools.",
				"Our Foundation’s investment in BRIDGE seeks to strengthen school performance through two levers for change. The first is to replicate best practice from a coalition of functional low-fee independent schools in Ekurhuleni North. The second is to identify deficiencies in the learning of Mathematics and English and address these through dialogue and collaboration with heads of department. ",
			],
			link: {
				modifier: "",
				target: "_blank",
				text: "View website",
				url: "http://www.bridge.org.za/",
			},
		},
		{
			image: {
				src: "/assets/images/our-partners/partner-oot-stem2D.jpg",
				alt: "Science, Technology, Engineering, Manufacturing and Design: OOT-STEM2D",
			},
			logo: {
				src: "/assets/images/our-partners/logo/logo-junior-achievement.png",
				alt: "Junior Achievement South Africa",
			},
			tag: "OOT-STEM2D",
			title: "Junior Achievement South Africa",
			description: [
				"Junior Achievement (JA) South Africa focuses on providing entrepreneurial and life skills to youth in both rural and urban environments. The Foundation partnered with JASA in entrepreneurship and work readiness programmes, with a specific focus on technology, robotics and programming.",
				"The OOT- STEM2D programme sees 400 high school learners from disadvantaged communities in three provinces participate in a programme that will prepare them for careers in technology and engineering.",
			],
			link: {
				modifier: "",
				target: "_blank",
				text: "View website",
				url: "https://jasa.org.za/",
			},
		},
		{
			image: {
				src: "/assets/images/our-partners/partners-value-based-leadership.jpg",
				alt: "Values-based Leadership Programme",
			},
			logo: {
				src: "/assets/images/our-partners/logo/logo-columba-leadership.png",
				alt: "Columba Leadership ",
			},
			tag: "Values-based Leadership Programme",
			title: "Columba Leadership",
			description: [
				"Columba Leadership offers a values-based leadership development programme at secondary school level, with the aim of creating environments more conducive to teaching and learning and to unleash the potential of young people. Columba recognises that young people have the potential to change their lives, schools and communities.",
				"The Youth-Based Leadership Programme sees our Foundation supporting nine schools across five provinces, partnering with Columba Leadership to build the human capital of principals, educators and learners.",
			],
			link: {
				modifier: "",
				target: "_blank",
				text: "View website",
				url: "http://www.columba.org.za/",
			},
		},
		{
			image: {
				src: "/assets/images/our-partners/partners-after-school.jpg",
				alt: "Out-of-school Learner Support Programmes",
			},
			logo: {
				src: "/assets/images/our-partners/logo/logo-ikamvayouth.png",
				alt: "IkamvaYouth",
			},
			tag: "Out-of-school Learner Support Programmes",
			title: "IkamvaYouth",
			description: [
				"IkamvaYouth offers a youth-driven, low-cost and high-impact programme, achieving results in challenging contexts. The programme offers services including academic tutoring, psycho-social support and career guidance, aiming to enable disadvantaged youth to gain tertiary education or employment.",
				"The Foundation’s partnership with IkamvaYouth will extend their existing programme to 225 high school learners in disadvantaged communities across three provinces. The funding will also allow for the development of sectoral guidelines that aim to lift the quality of out-of-school learner support programmes.",
			],
			link: {
				modifier: "",
				target: "_blank",
				text: "View website",
				url: "http://ikamvayouth.org/",
			},
		},
		{
			image: {
				src: "/assets/images/our-partners/partners-artisan-training.jpg",
				alt: "Artisan Development Programme",
			},
			logo: {
				src: "/assets/images/our-partners/logo/logo-artisan-training.png",
				alt: "Artisan Training Institute",
			},
			tag: "Artisan Development Programme",
			title: "Artisan Training Institute",
			description: [
				"The Artisan Training Institute (ATI) is making a substantial difference in the technical training environment as it drives quality training throughout its operations. It runs the Artisan Development Programme, an innovative apprenticeship model helping unemployed youth to learn trade skills. This initiative is the first to establish market demand for various trade skills before advocating for buy-in from employers.",
				"Our Foundation partnered with the ATI to launch a technical skills programme. This partnership aims to provide quality training to young people entering South Africa's engineering trades. The programme aims to support 10 young people from disadvantaged communities each year, over a 5 year period, 50% of whom will be female.",
			],
			link: {
				modifier: "",
				target: "_blank",
				text: "View website",
				url: "https://www.artisantraining.co.za/",
			},
		},
		{
			image: {
				src: "/assets/images/our-partners/partners-bonds4jobs.jpg",
				alt: "Bonds4Jobs",
			},
			logo: {
				src: "/assets/images/our-partners/logo/logo-harambee.png",
				alt: "Harambee",
			},
			tag: "Bonds4Jobs",
			title: "Bonds4Jobs",
			description: [
				"Bonds4Jobs works to find pragmatic implementable solutions to the issue of youth unemployment. The Bonds4Jobs initiative is a pay-for-performance bond focused on catalysing and growing the work-ready pool of excluded youth for key growth sectors.",
				"Our Foundation leveraged its grant funding to come on-board as an early adopter first loss capital investor in the bond platform. The intervention sought to test and demonstrate new skilling pathways to move excluded young people from learning to high-value jobs in demand sectors. Evidence of success is now creating space for a review of the credentialing ecosystem in SA.",
			],
			link: {
				modifier: "",
				target: "_blank",
				text: "View website",
				url: "http://harambee.co.za/",
			},
		},
		{
			image: {
				src: "/assets/images/our-partners/partners-youth-employment.jpg",
				alt: "Youth Employment and Skills Development Research Project",
			},
			logo: {
				src: "/assets/images/our-partners/logo/logo-CDE.png",
				alt: "Centre for Development and Enterprise",
			},
			tag: "Youth Employment and Skills Development Research Project",
			title: "Centre for Development and Enterprise",
			description: [
				"The CDE is an independent policy research and advocacy organisation. The Youth Employment and Skills Development Research Project aims to deepen the existing knowledge on young people who are not in employment education or training (NEET), particularly those without a matric qualification.",
				"Our Foundation partnered with the CDE to commission this project, which has a specific focus on the training and employment opportunities available to NEETs, the causes and factors exacerbating their plight and the existing interventions relevant to this area. The goal is to create a reform strategy for training and employment opportunities and to advocate for changes to policy and delivery in this regard.",
			],
			link: {
				modifier: "",
				target: "_blank",
				text: "View website",
				url: "https://www.cde.org.za/",
			},
		},
		{
			image: {
				src: "/assets/images/our-partners/partners-post-schooling.jpg",
				alt: "Feenix Tutuwa Student Historical Debt Fund",
			},
			logo: {
				src: "/assets/images/our-partners/logo/logo-feenix.png",
				alt: "FEENIX Trust",
			},
			tag: "Feenix Tutuwa Student Historical Debt Fund",
			title: "FEENIX Trust",
			description: [
				"The FEENIX Trust aims to achieve debt-free graduation for students on its platform. Their operations are partly sponsored by Standard Bank SA, allowing 95% of funds to be disbursed to students’ university accounts. FEENIX launched its crowdfunding platform in 2017 and equips university students as entrepreneurs in fundraising toward their own debt-free education.",
				"Our Foundation partnered with FEENIX to fund the historical debt of 21 students, enabling them to obtain their qualifications and graduate debt-free. The investment unlocks post-university employment opportunities for the students who have completed their studies.",
			],
			link: {
				modifier: "",
				target: "_blank",
				text: "View website",
				url: "https://www.feenix.org/",
			},
		},
		{
			image: {
				src: "/assets/images/our-partners/partners-intergrated-public-schools.jpg",
				alt: "Integrated Public-schools Initiative ",
			},
			logo: {
				src: "/assets/images/our-partners/logo/logo-rhodes.png",
				alt: "Rhodes University",
			},
			tag: "Integrated Public-schools Initiative ",
			title: "Rhodes University",
			description: [
				"Our Foundation is funding an integrated public-schools initiative aiming to revive and transform the no-fee paying public schooling system in Makhanda, Eastern Cape.",
				"The initiative aims to improve learner educational outcomes, from their earliest years to primary and high school, to enable the learners to complete high school with quality results and have an opportunity to access and enrol at Rhodes University and other institutions of higher learning.",
			],
			link: {
				modifier: "",
				target: "_blank",
				text: "View website",
				url: "https://www.ru.ac.za/",
			},
		},
		{
			image: {
				src: "/assets/images/our-partners/partners-schooling.jpg",
				alt: "Integrated Public-schools Initiative ",
			},
			logo: {
				src: "/assets/images/our-partners/logo/logo-jet.png",
				alt: "Rhodes University",
			},
			tag: "COVID-19 Researchers Bootcamp",
			title: "JET Education Services",
			description: [
				"JET has conceptualised the COVID-19 Researchers Bootcamp #OpenupYourThinking project as a response to the current pandemic. It is an opportunity for researchers who want to do something about the threat of COVID-19 and its implications for education. ",
				"The project’s aims include to devise solutions to the pressures being placed on the education system; allow for real-time inputs into other national education processes; allow young researchers to work under the guidance of experienced researchers during the lockdown period; influence future policy addressing the “new normal”; Disseminate and curate credible information within the sector.",
			],
			link: {
				modifier: "",
				target: "_blank",
				text: "View website",
				url: "https://www.jet.org.za/",
			},
		},
	]
};

export { partnersData };
