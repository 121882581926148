
import React from "react";

import './milestone-card.scss'

export default function milestoneCard(props) {
	
	const milestone = props;
	
	return (

		<div className="milestone-card">

			<div>

				<div className="milestone-card__date">
					{milestone.year}
				</div>

				<div className="milestone-card__title">
					{milestone.title}
				</div>

			</div>
            
			<div className="milestone-card__description">
				{milestone.description}
			</div>
            
		</div>
		
	);

}
