import React,{} from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import Logo from '../../atoms/logo/logo';

import "./header.scss";


const Header = () => {

	const toggleMenu = () => {
		const nav = document.getElementById("js-navbar");
		nav.classList.toggle("header__navbar--open");
	}

	const closeMenu = () => {
		document.getElementById("js-hamburger").click();
        document.body.scroll({top: 0, left: 0, behavior: 'smooth' }) 
	}

    
    const scrollWithOffset = (el) => {
        el.scrollIntoView({behavior: "smooth", block: "start"});
	};
      

	return (


		<header>
			
			<div className="header" id="js-main-menu">

				<div className="fullwidth-content header__content">

					<div className="header__nav-toggle">

						<input
							onChange={toggleMenu}
							id="js-hamburger"
							type="checkbox"
						/>

						<span></span>
						<span></span>
						<span></span>

					</div>

					<div className="header__logo">

						<Logo />

					</div>

					<div className="header__navbar" id="js-navbar">

 
						<ul className="header__nav-list">

							<li className="header__nav-item header__nav-item--dropdown">

								<NavLink
									className="header__nav-link"
									activeClassName="header__nav-link--active"
									onClick={closeMenu}
									to="/home"
								>
									Home
								</NavLink>
                    

								<ul className="header__dropdown">

									<li className="header__dropdown-item">
										<Link
											className="header__dropdown-link"
                                            scroll={scrollWithOffset}
											to="/#highlights"
										>
											Highlights
										</Link>
									</li>

									<li className="header__dropdown-item">
										<Link
											className="header__dropdown-link"
                                            scroll={scrollWithOffset}
											to="/#impact"
										>
											Impact
										</Link>
									</li>

									<li className="header__dropdown-item">
										<Link
											className="header__dropdown-link"
                                            scroll={scrollWithOffset}
											to="/#publications"
										>
											Publications
										</Link>
									</li>

								</ul>

							</li>

							<li className="header__nav-item header__nav-item--dropdown">

								<NavLink
									className="header__nav-link"
									activeClassName="header__nav-link--active"
									onClick={closeMenu}
									to="/about"
								>
									About Us
								</NavLink>

								<ul className="header__dropdown">

									<li className="header__dropdown-item">
										<Link
											className="header__dropdown-link"
                                            scroll={scrollWithOffset}
											to="/about/#our-vision"
										>
											Our Vision
										</Link>
									</li>

									<li className="header__dropdown-item">
										<Link
											className="header__dropdown-link"
                                            scroll={scrollWithOffset}
											to="/about/#our-history"
										>
											Our History
										</Link>
									</li>

									<li className="header__dropdown-item">
										<Link
											className="header__dropdown-link"
                                            scroll={scrollWithOffset}
											to="/about/#milestones"
										>
											Milestones
										</Link>
									</li>

									<li className="header__dropdown-item">
										<Link
											className="header__dropdown-link"
                                            scroll={scrollWithOffset}
											to="/about/#our-team"
										>
											Our Team
										</Link>
									</li>

									<li className="header__dropdown-item">
										<Link
											className="header__dropdown-link"
                                            scroll={scrollWithOffset}
											to="/about/#our-board"
										>
											Our board
										</Link>
									</li>

									<li className="header__dropdown-item">
										<Link
											className="header__dropdown-link"
                                            scroll={scrollWithOffset}
											to="/about/#frequently-asked-questions"
										>
											FAQs
										</Link>
									</li>

								</ul>

							</li>

							<li className="header__nav-item header__nav-item--dropdown">

								<NavLink
									className="header__nav-link"
									activeClassName="header__nav-link--active"
									onClick={closeMenu}
									to="/programmes"
								>
									Our Programmes
								</NavLink>

								<ul className="header__dropdown">

									<li className="header__dropdown-item">
										<Link
											className="header__dropdown-link"
                                            scroll={scrollWithOffset}
											to="/programmes/#our-partners"
										>
											Our Partners
										</Link>
									</li>

									<li className="header__dropdown-item">
										<Link
											className="header__dropdown-link"
                                            scroll={scrollWithOffset}
											to="/programmes/#reports"
										>
											Reports
										</Link>
									</li>

								</ul>

							</li>

							<li className="header__nav-item header__nav-item--dropdown">

								<NavLink
									className="header__nav-link"
									activeClassName="header__nav-link--active"
									onClick={closeMenu}
									to="/media-and-news"
								>
									Media & News
								</NavLink>

								<ul className="header__dropdown">

									<li className="header__dropdown-item">
										<Link
											className="header__dropdown-link"
                                            scroll={scrollWithOffset}
											to="/media-and-news/#video-gallery"
										>
											Videos
										</Link>
									</li>

									<li className="header__dropdown-item">
										<Link
											className="header__dropdown-link"
                                            scroll={scrollWithOffset}
											to="/media-and-news/#photo-gallery"
										>
											Photo Gallery
										</Link>
									</li>

									<li className="header__dropdown-item">
										<Link
											className="header__dropdown-link"
                                            scroll={scrollWithOffset}
											to="/media-and-news/#tutuwa-partners"
										>
											Tutuwa & Partners
										</Link>
									</li>

								</ul>

							</li>

							<li className="header__nav-item">
								<NavLink
									to="/contact"
									onClick={closeMenu}
									className="header__nav-link"
								>
									Contact Us
								</NavLink>
							</li>

							
						</ul> 

					</div>

				</div>

			</div>
			
		</header>
		
	);

};

export default Header;
