
import HomePage from './pages/home';
import About from './pages/about';
import Programmes from './pages/programmes';
import MediaAndNews from './pages/media-and-news';
import Contact from './pages/contact';
import SiteMap from './pages/sitemap';
import Legal from './pages/legal';
import Terms from './pages/terms-and-conditions';

const routesConfig = [

	{
		path: '/about',
		component: About
	},
	{
		path: '/programmes',
		component: Programmes
	},
	{
		path: '/media-and-news',
		component: MediaAndNews
	},
	{
		path: '/contact',
		component: Contact
	},
	{
		path: '/sitemap',
		component: SiteMap
	},
	{
		path: '/legal',
		component: Legal
	},
	{
		path: '/terms-and-conditions',
		component: Terms
	},
	{
		path: '/',
		component: HomePage
	}
	
];

export default routesConfig;

