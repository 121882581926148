import React from 'react'
import Helmet from "react-helmet"

import { introBannerData } from '../data/media-and-news/intro-banner'
import { articlesData } from '../data/media-and-news/articles'

import BreadCrumb from '../components/atoms/bread-crumb/breadcrumb'
import IntroBanner from '../components/molecules/intro-banner/intro-banner'
import Filter from '../components/molecules/filter/filter'
import Gallery from '../components/organisms/gallery/gallery'
import ArticleCarousel from '../components/organisms/article-carousel/article-carousel'

class MediaAndNews extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			filterItems: ['all', 'videos', 'images', 'articles'],
			filterActive: 'all',
		}
	}

	filterClickHandler = (filterValue) => {
		this.setState({ filterActive: filterValue })
	}

	filterOutput = () => {}

	render() {
		return (
			<div className="media-and-news">
                

                <Helmet>
                    <title>The Standard Bank Tutuwa Community Foundation: Media & News</title>
                    <meta name="title" content="The Standard Bank Tutuwa Community Foundation: Media & News"/>
                    <meta name="description" content="Towards a South Africa where youth reach their potential, contributing to the economic development of their communities."/>
                    <meta name="keywords" content="standard bank; tutuwa foundation; reducing poverty; poverty reduction; poverty reduction south africa;  social investments; early childhood development ecd; benefit communities in south africa; impact investing; social impact bond"/>
                    <meta name="robots" content="index, follow"/>
                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                    <meta name="language" content="English"/>
                </Helmet>


				<IntroBanner
					title={introBannerData.title}
					description={introBannerData.description}
					button={introBannerData.button}
					background={introBannerData.background}
				></IntroBanner>

				<nav>
					<BreadCrumb section="News & Media" />
				</nav>

				<Filter
					items={this.state.filterItems}
					active={this.state.filterActive}
					filterClick={this.filterClickHandler}
				/>

				<div className="scroll" 
					id="video-gallery">
				</div>

				{this.state.filterActive === 'videos' ||
				this.state.filterActive === 'all' ? (

					<div className="content-wrapper">

						<Gallery type="videos" />

					</div>

				) : null}

				<div className="scroll" 
					id="photo-gallery">
				</div>

				{this.state.filterActive === 'images' ||
				this.state.filterActive === 'all' ? (

					<div className="content-wrapper content-wrapper--gray">
                        
						<Gallery type="images" />
                        
					</div>

				) : null}

				<div className="scroll" 
					id="tutuwa-partners">
				</div>

				{this.state.filterActive === 'articles' ||
				this.state.filterActive === 'all' ? (

					<div className="content-wrapper" >
						<ArticleCarousel
							type="articles"
							title={articlesData.title}
							description={articlesData.description}
							slides={articlesData.articles}
						/>
					</div>

				) : null}

			</div>
		)
	}
}

export default MediaAndNews
