
import React from 'react'

function InvestmentStages(props) {
	const { stages, active } = props

	const isActive = (val) => {
		return (
			active.replace(/\s+/g, '-').toLowerCase() ===
			val.replace(/\s+/g, '-').toLowerCase()
		)
	}

	const stagesItems = stages.map((stage) => (

		<div 
            className={`infographic__stages-item${isActive(stage) ? ` active` : ``} `}
			onClick={() => props.investStageSelect(stage)}
            key={stage}>

			<div className="infographic__stages-state"></div>

			<span>
				{stage}
			</span>

		</div>

	))
	return stagesItems
}

export default InvestmentStages
