import React from 'react'

import CountUp from 'react-countup'

import './counter.scss';

function Counter(props) {
    const amount = props.amount;

	return (

		<CountUp
			start={0}
			end={amount}
			delay={0}
			separator=" "
			decimals={2}
			decimal=","
			prefix="R "
		>

			{({ countUpRef }) => (

				<div className="counter">

					<span ref={countUpRef} />

				</div>
                
			)}
		</CountUp>

	)
}

export default Counter
