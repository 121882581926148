import React from 'react'

import Counter from '../../atoms/counter/counter'

import { ResponsiveContainer, RadialBarChart, RadialBar } from 'recharts'

export default function InvestmentGraphRadial(props) {

	const {
		percentage,
		amount
	} = props;

	const graphData = [
		{ amount: percentage, fill: '#ffb81e' },
		{ amount: 100, fill: '#ffffff' },
	]

	return (

		<div className="infographic__overall">

			<h3 className="infographic__slide-item-title">
				Investment Amount to Date
			</h3>

			<div className="infographic__overall-content">

				<div className="infographic__overall-counter">

					<Counter amount={amount}></Counter>

				</div>

				<div className="infographic__radial-graph">

					<div className="infographic__radial-graph-detail">

						<span>To date</span>

						<Counter amount={amount}></Counter>

						<div className="infographic__radial-graph-percentage">
							{percentage}%
						</div>

						<span>Invested</span>
						
					</div>

					<ResponsiveContainer width={260} height={260}>

						<RadialBarChart
							innerRadius="80%"
							outerRadius="100%"
							startAngle={210}
							endAngle={-30}
							data={graphData}>

							<RadialBar
								barSize={10}
								barGap={-50}
								cornerRadius="20"
								dataKey="amount" />
								
						</RadialBarChart>

					</ResponsiveContainer>

				</div>

			</div>
			
			
		</div>
	)
}
