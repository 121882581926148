import React from "react";
import Helmet from "react-helmet";

class Legal extends React.Component {

    componentDidUpdate(){
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    render() {

        return (

            <div className="legal">

                <Helmet>
                    <title>The Standard Bank Tutuwa Community Foundation: Legal </title>
                    <meta name="title" content="The Standard Bank Tutuwa Community Foundation: Legal "/>
                    <meta name="description" content="We aim to help young South Africans reach their full potential and make an active contribution to their community."/>
                    <meta name="keywords" content="Standard bank; tutuwa foundation; South African youth; investing in South Africa’s youth; social impact initiatives; economic development; investing in social impact bonds; impact investing; thought leadership; economic growth and development; industrial development; department of trade, industry and competition; youth empowerment; black economic empowerment"/>
                    <meta name="robots" content="index, follow"/>
                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                    <meta name="language" content="English"/>
                </Helmet>

				
				<div className="content-wrapper">

					<div className="content">

						<h2>PRIVACY STATEMENT</h2>

						<p><b>Privacy statement of The Standard Bank Tutuwa Community Foundation NPC</b>, The Standard Bank Tutuwa Community Foundation NPC,  a non profit company affiliated with The Standard Bank of South Africa Limited (the Foundation,we, us, our) treats personal information we collect through this website, associated websites, mobile sites, mobile applications and other channels as private and confidential.</p>

						<h4>Collection</h4>

						<p>We collect personal information directly from you, and where lawful and reasonable, we may collect personal information about you from third parties and publicly available sources, such as credit reporting and government agencies for the purposes set out below.</p>
					
						<p><b>We use your personal information to:</b></p>

						<p>
							<ul>
								<li>meet our responsibilities to you</li>
								<li>follow your instructions</li>
								<li>process your personal information for ordinary business purposes (this includes to manage our risks and maintain our overall relationship with you)</li>
								<li>carry out statistical and other analyses to identify potential markets and trends, evaluate and improve our business (this includes improving existing and developing new products and services);</li>
								<li>make sure our business suits your needs, and</li>
								<li>comply with applicable <a href="https://www.standardbank.co.za/southafrica/personal/about-us/regulatory" target="_blank">regulations</a>.</li>
							</ul>
						</p>

						<p>Without your personal information, we may not be able to provide or continue to provide you with the products or services that you need.</p>

						<h4>Your consent</h4>

						<p>We will process your personal information if you give us your consent willingly or in accordance with the law. You give your consent to us through our product and services agreements. We will only disclose your personal information if:</p>
					
						<p>
							<ul>
								<li>the law requires it;</li>
								<li>we have a public duty to disclose the information;</li>
								<li>our or your legitimate interests require disclosure; or</li>
								<li>you agreed that we may disclose your information.</li>
							</ul>
						</p>

						<h4>Third parties</h4>

						<p>We ask individuals, organisations and our subsidiaries and affiliates for support. When we do this, they have to agree to our privacy principles, associated policies and practices.</p>
					
						<p>We will not disclose your personal information to external organisations that are not our service providers, unless you gave us your consent, or unless we may do so by law, or if it is necessary for the conclusion or performance of our agreement with you.</p>
					
						<h4>Transfer across borders</h4>

						<p>Sometimes we will process your personal information in other countries, either to carry out your instructions or for ordinary business purposes. These countries may not have the same level of protection. We will only process your personal information with your consent. If necessary, we will ask the party to whom we transfer your personal information to agree to our privacy principles, associated policies and practices.</p>

						<h4>Storing personal information</h4>

						<p>We store personal information as required by law.</p>

						<h4>Our security practices</h4>

						<p>Our security systems are designed to prevent loss, unauthorised destruction, damage and/or access to your personal information from unauthorised third parties.</p>
						
						<h4>Marketing by electronic means</h4>

						<p>If you give us permission, we may use your personal information to tell you about products, services and special offers from us or other companies that may interest you. We may do this through email, text message (SMS), and/or social media platforms (if applicable). If you later decide that you do not want us to do this, please contact us through our customer service channels to stop this service.</p>
						
						<p>You should read the security tips and updates on our website regularly to make sure that you benefit from our security systems, and stay updated with the latest fraud scams and trends.</p>
					
						<h4>Access to your personal information</h4>

						<p>If we collect your personal information, you may: (a) ask us to give you a description of your personal information that we hold; and (b) ask us to correct or update your personal information through our customer service channels.</p>
					
						<p>We may, if allowed by law, charge a fee for this.</p>

						<h4>Our use of technology to follow your use of our website</h4>

						<p>While you are using our websites or mobile applications, we automatically process certain personal information, such as visits to our website. We use this information to find out which areas of our website people visit most and to monitor the use of our websites. This helps us to add more value to our services. This information is gathered in such a way that we do not get personal information about any individual or their online behaviour on other websites.</p>

						<h4>Cookies</h4>

						<p>We use cookie technology on some of our websites. A cookie is small pieces of text that is saved on your Internet browser when you use our websites. The cookie is sent to our computer each time you visit our websites. Cookies make it easier for us to give you a better experience online. You can stop your browser from accepting cookies, but if you do, some parts of our websites or online services may not work. We recommend that you allow cookies.</p>
					
						<h4>Links to other websites</h4>

						<p>Our website, related websites and mobile applications may have links to or from other websites. Although we try to link only to websites that also have high privacy standards, we are not responsible for their security, privacy practices or content. We recommend that you always read the privacy and security statements on these websites.</p>
					
						<h4>Monitoring of electronic communications</h4>

						<p>We communicate with you through different methods and channels. If allowed by law, we may record and monitor electronic communications to make sure that they comply with our legal and regulatory responsibilities and internal policies.</p>
					
						<h4>Monitoring and analysis</h4>

						<p>We will monitor and analyse your account for credit, fraud, compliance and other risk-related purposes as required by law.</p>
					
						<h4>Social media (if applicable)</h4>

						<p>We operate and communicate through our designated channels, pages and accounts on some social media sites to inform, help and engage with our customers. We monitor and record comments and posts made about us on these channels so that we can improve our services.</p>
					
						<p>The general public can access and read any information posted on these sites. We are not responsible for any information posted on those sites other than the information posted by our designated officials. We do not endorse the social media sites themselves, or any information posted on them by third parties or other users.</p>
					
						<p>When you engage with us through social media your personal information may be processed by the site owner; this process is outside our control and may be in a country outside South Africa that may have different privacy principles.</p>
					
						<p>We regularly update and monitor our social media accounts and welcome feedback and ideas sent to us through these channels. We try to join conversations whenever possible, but cannot guarantee that we will read or reply to all messages sent to official Foundation social media accounts.</p>
					
						<p>Emerging themes and helpful suggestions will be given to the relevant people within the Foundation for consideration, but we cannot guarantee that any themes or suggestions will be acted on.</p>

						<h4>Your rights</h4>

						<p>We will take note of your rights under applicable privacy and data protection laws, especially your right to object, on reasonable grounds, to certain types of processing.</p>

						<p>You have the right to query a decision that we make about a product or service that you have applied for and that was made solely by automated means.</p>

						<h4>Right to change this privacy statement</h4>

						<p>We may change this privacy statement. We will publish all changes on our website. The latest version of our privacy statement will replace all earlier versions, unless it says differently.</p>
					
						<h4>* Affiliate companies</h4>

						<p>Standard Bank Group Limited, and Standard Bank of South Africa Limited</p>

						<h4>Queries and complaints</h4>

						<p>
							Jan Bezuidenhout<br />
							Group Privacy Officer<br />
							Postal Address: The Standard Bank of South Africa Limited<br />
							P O Box 1155<br />
							Johannesburg<br />
							2000
						</p>

						<p>
							Physical address:<br />
							Standard Bank Centre<br />
							5 Simmonds Street<br />
							Johannesburg<br />
							2001
						</p>

						<p>
							Telephone: +27 11 636 1781
							Fax: +27 11 631 8580
							Email: <a href="mailto:info.officer@standardbank.co.za" target="_blank">info.officer@standardbank.co.za</a>
						</p>
					
					</div>
					

				</div>
				

            </div>

		);
		
	}
	
}

export default Legal;
