
const whatWeDoData = [
	{
		title: "Grant-making",
		subTitle: "Our grant-making strategy:",
		description: [
			'<ul><li>We identify existing initiatives that work.</li><li>We conduct research and assess the impact and efficacy of this existing intervention.</li><li>We identify new approaches.</li><li>We provide a platform for the dissemination of our findings and activate networking.</li><li>We strengthen linkages in the theme areas.</li></ul>',
		],
		image: {
			src: "/assets/images/what-we-do/tab-grant-making.jpg",
			alt: "Our Vision",
		},
	},
	{
		title: "Impact Investing",
		subTitle: "We invest with the intention of generating a measurable, beneficial social impact.",
		description: [
			'<b>Why we use blended funding models:</b><br /> We implement funding models that allow for the leveraging of grant funding to unlock and attract other sources of finance, including investments and loans from alternative funding channels. Whilst the core principles of allocating grants to established causes is retained, the leveraging of this contribution offers an added benefit.',
		],
		image: {
			src: "/assets/images/what-we-do/tab-leadership.jpg",
			alt: "Impact Investing",
		},
	},
	{
		title: "Thought Leadership",
		subTitle: "How we spread our knowledge to other NPOs.",
		description: [
			'Support for gathering and dissemination of knowledge enables other non-profit organisations (NPO), government programmes and corporate foundations (funders) to refine processes, resulting in extended impact, better implementation and overall systems improvement. The thought leadership function requires an investment over and above programmatic funding and activities beyond an operational focus.',
		],
		image: {
			src: "/assets/images/what-we-do/tab-investing.jpg",
			alt: "Thought Leadership",
		},
	},
]

export { whatWeDoData };
