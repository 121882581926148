
import React from 'react';

import './slide-counter.scss';

const SlideCounter = (props) => {

	return (

		<span className="slide-counter">

			<div className="slide-counter__item slide-counter__item--active">
				{props.start}
			</div>
			
			<div className="slide-counter__item">
            	{props.end}
			</div>

		</span>

	);

};

export default SlideCounter;
