
import React, { useState } from 'react'
import { HashLink as Link } from "react-router-hash-link";

import ResponsiveImage from '../../atoms/responsive-image/responsive-image';
import Pattern from '../../atoms/pattern/pattern';

import "./history.scss";

const History = (props) => {
    
	const {
		title,
		subTitle,
		content,
		contentExpanded,
		background
	} = props;

	const [isContentExpanded, setIsContentExpanded] = useState(false)

	function toggleContent() {

		setIsContentExpanded(!isContentExpanded) 

		if (!isContentExpanded) {
			
		}

	}

	function sanitize(item) {

		return {
			__html: item
		};
		
	}

	return (

		<div className="history"
			id="history">

			<div className="content">

				<div className="history__content">

					<h2>
						{title}
					</h2>

					<p>
						<b>
							{subTitle}
						</b>
					</p>

					{content.map((item, index) => {

						return (

							<p dangerouslySetInnerHTML={ sanitize(item) }
								key={index}>
							</p>
						);
														
					})}

					{!isContentExpanded &&
					
						<div className="link" 
							onClick={() => toggleContent()} >
								Read more
						</div>

					}

					{isContentExpanded &&

						<>
						
						<div className="history__expanded">

							{contentExpanded.map((item, index) => {

								return (

									<p dangerouslySetInnerHTML={ sanitize(item) }
										key={index}>
									</p>
								);
														
							})}

						</div> 		

						<Link
							className="link"
							smooth
							onClick={() => toggleContent()}
							to="/about/#history"
						>
							Close
						</Link>

						</>
					
					}

				</div>

			</div>

			<div className="history__background">

				<div className="history__pattern">
					<Pattern/>
				</div>

				<div className="history__image">

					<ResponsiveImage i
						mobile={background.mobile}
						desktop={background.desktop}
						alt={background.alt}>
					</ResponsiveImage>

				</div>
				

			</div>

		</div>
        
	);
};

export default History;
