
import React from 'react';

import './intro.scss';

const Intro = (props) => {

	const {
		title,
		description
	} = props;

	function sanitize(item) {

		return {
			__html: item
		};
		
	}

	return (

		<div className="intro">

			{title && (

				<h2>{title}</h2>

			)}

			{description && (

				<div>

					{description.map( (item, index) => {

						return (

							<p dangerouslySetInnerHTML={ sanitize(item) }
								key={index}>
							</p>

						)
															
					})}

				</div>

			)}

		</div>

	)

}

export default Intro;
