import React from 'react'
import Helmet from "react-helmet"

import { introBannerData } from '../data/contact/intro-banner'
import { contactData } from '../data/contact/contact-form'
import { locationData } from '../data/contact/location'

import BreadCrumb from '../components/atoms/bread-crumb/breadcrumb'
import IntroBanner from '../components/molecules/intro-banner/intro-banner'
import ContactForm from '../components/organisms/contact-form/contact-form'
import Location from '../components/molecules/location/location'

class Contact extends React.Component {

	constructor() {
		super()

		this.state = {
			formSubmitted: false,
		}

	}

	toggleFormSubmitted = () => {
		this.setState({ formSubmitted: !this.state.formSubmitted })
	}


	render() {

		return (

			<div className="contact">

                <Helmet>
                    <title>Contact the Standard Bank Tutuwa Community Foundation</title>
                    <meta name="title" content="Contact the Standard Bank Tutuwa Community Foundation"/>
                    <meta name="description" content="Get in touch with the Foundation for more information on the work we do to offer young South Africans a brighter future."/>
                    <meta name="keywords" content="standard bank; tutuwa foundation; brighter future for young South Africans; contact the standard bank tutuwa community foundation; contact tutuwa community foundation; tutuwa foundation contact details"/>
                    <meta name="robots" content="index, follow"/>
                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                    <meta name="language" content="English"/>
                </Helmet>


				<IntroBanner
					title={introBannerData.title}
					description={introBannerData.description}
					button={introBannerData.button}
                    background={introBannerData.background}>
				</IntroBanner>

				<BreadCrumb section="Contact Us" />

				{!this.state.formSubmitted ? (

					<div className="content-wrapper">

						<ContactForm
							title={contactData.title}
							description={contactData.description}
							formSubmitted={this.toggleFormSubmitted}
						></ContactForm>
                        
					</div>

				) : null}

				{this.state.formSubmitted ? (

					<div className="content-wrapper content contact-form">

						<h4>Thank you!</h4>
                        
                        <p>Your form has been submitted. 
                            <span>We will be in touch</span>
                        </p>
                        
                        <button className="button" onClick={this.toggleFormSubmitted}>Send Another Message</button>

					</div>
                    
				) : null}

				<div className="content-wrapper content-wrapper--gray">
					<Location
						address={locationData.address}
						email={locationData.email}
						map={locationData.map}
					></Location>
				</div>
			</div>
		)
	}
}

export default Contact
