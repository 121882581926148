

import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import ResponsiveImage from '../../atoms/responsive-image/responsive-image';
import Pattern from '../../atoms/pattern/pattern';
import Button from '../../atoms/button/button';

import "./intro-banner.scss";

const IntroBanner = (props) => {

	const scrollWithOffset = (el) => {
        el.scrollIntoView({behavior: "smooth", block: "start"});
	};

	const {
		title,
		description,
		button,
		background
	} = props;

	return (

		<div className="intro-banner">

			<div className="content">

				<div className="intro-banner__content">

					<h1 className="intro-banner__title">
						{title}
					</h1>

					<p className="intro-banner__text">
						{description}
					</p>

					{button && (

						<Link
							className="button"
							scroll={scrollWithOffset}
							to={button.url}
						>
							{button.text} 
						</Link>

					)}

				</div>

			</div>

			<div className="intro-banner__background">

				<div className="intro-banner__pattern">
					<Pattern/>
				</div>

				<div className="intro-banner__image">

					<ResponsiveImage i
						mobile={background.mobile}
						desktop={background.desktop}
						alt={background.alt}>
					</ResponsiveImage>

				</div>
				

			</div>

		</div>
        
	);
};

export default IntroBanner;
