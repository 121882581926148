
const principlesData = [
	{
		title: "Our Vision and Our Mission",
		description: [
			'A South Africa whose youth are inspired and supported and working to reach their full potential as productive citizens contributing to the economic development of their communities.<br />To inspire and support the growth and development of young people so that they can reach their full potential as productive citizens who contribute to the economic development of their communities.',
		],
		image: {
			src: "/assets/images/principles/tab-our-vision.jpg",
			alt: "Our Vision and Our Mission",
		},
	},
	{
		title: "Our Strategic Objectives",
		description: [
			'Our Foundation aims to achieve social change through its investment approach with a focus on youth, specifically in three main areas: early childhood development (ECD), schooling and post-schooling.',
			'Our strategic objectives are:',
			'<b>ECD</b><br />To contribute to educational interventions in children’s earliest years to improve learning outcomes (school readiness) at an ECD level.',
			'<b>Schooling</b><br />To contribute towards making the schooling system more effective to improve learner outcomes at a schooling level.',
			'<b>Post schooling</b><br />To support young people’s transition to post-schooling opportunities.',
			'<b>A legacy focus</b> <br />We aim to create a long-lived legacy by focusing on our mission and ensuring that every young person, regardless of their socio-economic background is empowered to reach their full potential, with equal access to opportunities and support to become productive citizens. Our focus in this regard is on youth that are not in education, employment and training (NEETS) to ensure they are given opportunities to transform their lives.',
		]
	},
]

export { principlesData };
