
const boardData = {

	title: "Our Board",
	board: [
		{
			image: {
				src: "/assets/images/team/mpho.jpg",
				alt: "Mpho Letlape",
			},
			name: "Mpho Letlape",
			title: "Chair of the board",
		},
		{
			image: {
				src: "/assets/images/team/sarah.jpg",
				alt: "Sarah Rennie",
			},
			name: "Sarah Rennie",
			title: "Director",
		},
		{
			image: {
				src: "/assets/images/team/philile.jpg",
				alt: "Philile Maphumulo",
			},
			name: "Philile Maphumulo",
			title: "Director",
		},
		{
			image: {
				src: "/assets/images/team/simon.jpg",
				alt: "Simon Ridley",
			},
			name: "Simon Ridley",
			title: "Director",
		},
		{
			image: {
				src: "/assets/images/team/lungisa.jpg",
				alt: "Lungisa Fuzile",
			},
			name: "Lungisa Fuzile",
			title: "Director",
		},
	],

}

export { boardData };
