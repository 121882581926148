import React from "react";

import Intro from '../../atoms/intro/intro';

import { SwiperSlide } from "swiper/react";
import Carousel from "../carousel/carousel";
import HighlightCard from "../../molecules/highlight-card/highlight-card";

import "./highlight-carousel.scss";

const carouselConfig = {
	slidesPerView: 1.3,
	spaceBetween: 5,
	centeredSlides: true,
	initialSlide: 1,
	navigation: true,
	pagination: false,
	autoHeight: true,
	watchOverflow: true,
	breakpoints: {
        640: {
          slidesPerView: 1.6,
        },
        768: {
          slidesPerView: 1.3,
        },
        1024: {
          slidesPerView: 1.5,
		},
		1440: {
			slidesPerView: 2,
		},
	}
};


const HighlightCarousel = (props) => {

	const {
		slides,
		title,
		description
	} = props;
	
	return (

		<div className="highlight-carousel">

			<div className="content">

				<Intro
					title={title}
					description={description}>
				</Intro>

			</div>

			<Carousel config={carouselConfig}>

				{slides.map((item, index) => {

					return (

						<SwiperSlide 
							key={index} 
							effect="fade">

							<HighlightCard 
								content={item} index={index} length={slides.length}>					
							</HighlightCard>
							
						</SwiperSlide>
						
					);

				})}

			</Carousel>

		</div>

	);
};

export default HighlightCarousel;
