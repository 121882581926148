
import React from "react";

import { NavLink } from "react-router-dom";

import "./breadcrumb.scss";

const BreadCrumb = (props) => {

	if (props.section) {

		return (

		<div className="content">

			<ul className="breadcrumb">

				<li className="breadcrumb__item">

					<NavLink
						className="breadcrumb__nav-link"
						to="/home"
					>
						Home
					</NavLink>

				</li>
				
				<li className="breadcrumb__item breadcrumb__item--active">
					{props.section}
				</li>

			</ul>
				
		</div>

		);
		
	} else {
		return null;
	}
	
};

export default BreadCrumb;
