const imagesData = {
	title: 'Photo Gallery',
	description: [
        "Browse through a range of photo galleries focussing on important events held by our Foundation and its partners ",
        ],

	galleries: [
		{
            title: 'Meet our top scholars',
            description: 'Some pics of our scholarship programme’s top achievers, who matriculated in 2020',
            
			coverImage: {
				src: '/assets/images/media/gallery/top-scholars/top-scholar-thumb.jpg',
				alt: 'Meet our top scholars',
			},

			icon: {
				src: '/assets/icons/icon-view-gallery.png',
				alt: 'View photo gallery',
			},

			images: [
				{
					src: '/assets/images/media/gallery/top-scholars/top-scholar-01.jpg',
					alt: 'Top achievers - 2020',
				},
				{
					src: '/assets/images/media/gallery/top-scholars/top-scholar-02.jpg',
					alt: 'Top achievers - 2020',
				},
				{
					src: '/assets/images/media/gallery/top-scholars/top-scholar-03.jpg',
					alt: 'Top achievers - 2020',
				},
				{
					src: '/assets/images/media/gallery/top-scholars/top-scholar-04.jpg',
					alt: 'Top achievers - 2020',
				},
				{
					src: '/assets/images/media/gallery/top-scholars/top-scholar-05.jpg',
					alt: 'Top achievers - 2020',
				}                
			],
			
		},
        {
            title: 'Standard Bank Tutuwa Community Foundation launch',
            description: 'A gallery from our Foundation’s launch event in 2019.',
            
			coverImage: {
				src: '/assets/images/media/gallery/launch/launch-thumb.jpg',
				alt: 'Standard Bank Tutuwa Community Foundation launch',
			},

			icon: {
				src: '/assets/icons/icon-view-gallery.png',
				alt: 'View photo gallery',
			},

			images: [
				{
					src: '/assets/images/media/gallery/launch/launch-01.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-02.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-03.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-04.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-05.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-06.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-07.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-08.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-09.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-10.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-11.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				}, 
				{
					src: '/assets/images/media/gallery/launch/launch-12.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-13.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-14.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-15.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-16.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-17.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-18.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-19.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-20.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-21.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-22.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-23.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-24.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				},
				{
					src: '/assets/images/media/gallery/launch/launch-25.jpg',
					alt: 'Standard Bank Tutuwa Community Foundation launch',
				}
			],
		},
		{
            title: 'The Artisan Training Institute (2019)',
            description: 'A look inside the ATI, which is training tomorrow’s artisans',
            
			coverImage: {
				src: '/assets/images/media/gallery/ati-2019/ati-19-thumb.jpg',
				alt: 'The Artisan Training Institute - 2019',
			},

			icon: {
				src: '/assets/icons/icon-view-gallery.png',
				alt: 'View photo gallery',
			},

			images: [
				{
					src: '/assets/images/media/gallery/ati-2019/ati-19-01.jpg',
					alt: 'The Artisan Training Institute - 2019',
				},
				{
					src: '/assets/images/media/gallery/ati-2019/ati-19-02.jpg',
					alt: 'The Artisan Training Institute - 2019',
				},
				{
					src: '/assets/images/media/gallery/ati-2019/ati-19-03.jpg',
					alt: 'The Artisan Training Institute - 2019',
				},
				{
					src: '/assets/images/media/gallery/ati-2019/ati-19-04.jpg',
					alt: 'The Artisan Training Institute - 2019',
				},
				{
					src: '/assets/images/media/gallery/ati-2019/ati-19-05.jpg',
					alt: 'The Artisan Training Institute - 2019',
				},
				{
					src: '/assets/images/media/gallery/ati-2019/ati-19-06.jpg',
					alt: 'The Artisan Training Institute - 2019',
				},
				{
					src: '/assets/images/media/gallery/ati-2019/ati-19-07.jpg',
					alt: 'The Artisan Training Institute - 2019',
				},
				{
					src: '/assets/images/media/gallery/ati-2019/ati-19-08.jpg',
					alt: 'The Artisan Training Institute - 2019',
				},
				{
					src: '/assets/images/media/gallery/ati-2019/ati-19-09.jpg',
					alt: 'The Artisan Training Institute - 2019',
				},
				{
					src: '/assets/images/media/gallery/ati-2019/ati-19-10.jpg',
					alt: 'The Artisan Training Institute - 2019',
				}            
			],
			
		},
		{
            title: 'The Artisan Training Institute (2018)',
            description: 'A look inside the ATI, which is training tomorrow’s artisans',
            
			coverImage: {
				src: '/assets/images/media/gallery/ati-2018/ati-18-thumb.jpg',
				alt: 'The Artisan Training Institute - 2018',
			},

			icon: {
				src: '/assets/icons/icon-view-gallery.png',
				alt: 'View photo gallery',
			},

			images: [
				{
					src: '/assets/images/media/gallery/ati-2018/ati-18-01.jpg',
					alt: 'The Artisan Training Institute - 2018',
				},
				{
					src: '/assets/images/media/gallery/ati-2018/ati-18-02.jpg',
					alt: 'The Artisan Training Institute - 2018',
				},
				{
					src: '/assets/images/media/gallery/ati-2018/ati-18-03.jpg',
					alt: 'The Artisan Training Institute - 2018',
				},
				{
					src: '/assets/images/media/gallery/ati-2018/ati-18-04.jpg',
					alt: 'The Artisan Training Institute - 2018',
				},
				{
					src: '/assets/images/media/gallery/ati-2018/ati-18-05.jpg',
					alt: 'The Artisan Training Institute - 2018',
				},
				{
					src: '/assets/images/media/gallery/ati-2018/ati-18-06.jpg',
					alt: 'The Artisan Training Institute - 2018',
				},
				{
					src: '/assets/images/media/gallery/ati-2018/ati-18-07.jpg',
					alt: 'The Artisan Training Institute - 2018',
				},
				{
					src: '/assets/images/media/gallery/ati-2018/ati-18-08.jpg',
					alt: 'The Artisan Training Institute - 2018',
				},
				{
					src: '/assets/images/media/gallery/ati-2018/ati-18-09.jpg',
					alt: 'The Artisan Training Institute - 2018',
				},
				{
					src: '/assets/images/media/gallery/ati-2018/ati-18-10.jpg',
					alt: 'The Artisan Training Institute - 2018',
				}            
			],
			
		},
		{
            title: 'Child Gauge launch',
            description: 'This 2018 Gauge focuses on children at the interface of families and the state',
            
			coverImage: {
				src: '/assets/images/media/gallery/child-gauge/child-gauge-thumb.jpg',
				alt: 'Child Gauge launch',
			},

			icon: {
				src: '/assets/icons/icon-view-gallery.png',
				alt: 'View photo gallery',
			},

			images: [
				{
					src: '/assets/images/media/gallery/child-gauge/child-gauge-01.jpg',
					alt: 'Child Gauge launch',
				},
				{
					src: '/assets/images/media/gallery/child-gauge/child-gauge-02.jpg',
					alt: 'Child Gauge launch',
				},
				{
					src: '/assets/images/media/gallery/child-gauge/child-gauge-03.jpg',
					alt: 'Child Gauge launch',
				},
				{
					src: '/assets/images/media/gallery/child-gauge/child-gauge-04.jpg',
					alt: 'Child Gauge launch',
				},
				{
					src: '/assets/images/media/gallery/child-gauge/child-gauge-05.jpg',
					alt: 'Child Gauge launch',
				},
				{
					src: '/assets/images/media/gallery/child-gauge/child-gauge-06.jpg',
					alt: 'Child Gauge launch',
				},
				{
					src: '/assets/images/media/gallery/child-gauge/child-gauge-07.jpg',
					alt: 'Child Gauge launch',
				},
				{
					src: '/assets/images/media/gallery/child-gauge/child-gauge-08.jpg',
					alt: 'The Artisan Training Institute - 2018',
				},          
			],
			
		},
	],
}

export default { imagesData }
