
const teamData = {

	title: "Our Team",
	team: [
		{
			image: {
				src: "/assets/images/team/zanele.jpg",
				alt: "Zanele Twala",
			},
			name: "Zanele Twala",
			title: "CEO",
		},
		{
			image: {
				src: "/assets/images/team/justin.jpg",
				alt: "Justin Prozesky",
			},
			name: "Justin Prozesky",
			title: "Advisor",
		},
		{
			image: {
				src: "/assets/images/team/Phumla-Hobe-Yabo.png",
				alt: "Phumla Hobe-Yabo",
			},
			name: "Phumla Hobe-Yabo",
			title: "Head of Programmes and Operations",
		},
		{
			image: {
				src: "/assets/images/team/noreen.jpg",
				alt: "Noreen Moloi",
			},
			name: "Noreen Moloi",
			title: "PA and Team Administrator",
		},
		{
			image: {
				src: "/assets/images/team/riekie.jpg",
				alt: "Riekie van Tonder",
			},
			name: "Riekie van Tonder",
			title: "Finance, Compliannce and Business Manager",
		},
		{
			image: {
				src: "/assets/images/team/Thandi-Lekeba.png",
				alt: "Thandi Lekeba ",
			},
			name: "Thandi Lekeba ",
			title: "Scholar Development Officer",
		},
		{
			image: {
				src: "/assets/images/team/Kgaogelo-Seemola.png",
				alt: "Kgaogelo Seemola ",
			},
			name: "Kgaogelo Seemola ",
			title: "Scholar Development Officer",
		},
		
	],

}

export { teamData };
