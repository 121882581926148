
const publicationsData = {
	title: "Publications",
	description: [
		"A variety of resources highlighting the work we and our partners are doing in the promotion of economic development and alleviation of poverty, as well as looking at both progress and challenges affecting youth development in South Africa.",
	],
	publications: [
		{
			image: {
				type: "video",
				src: "/assets/images/publications/article-matric-graduation-2020.jpg",
				alt: "Foundation graduation class of 2020",
			},
			icon: {
				src: "/assets/icons/icon-play-video.png",
				alt: "Play video",
				url: "https://www.youtube.com/watch?v=XdHJwDGQYNE",
				target: "_blank",
			},
			tag: "2020-12-01",
			title: "Foundation graduation class of 2020",
			description: "Meet some of the learners from our scholarship programme who graduated in 2020.",
			link: {
				modifier: "",
				target: "_blank",
				text: "Play video",
				url: "https://www.youtube.com/watch?v=XdHJwDGQYNE",
			},
		},
		{
			image: {
				type: "video",
				src: "/assets/images/publications/article-ikamvayouth.jpg",
				alt: "Partnership in the after-school space",
			},
			icon: {
				src: "/assets/icons/icon-play-video.png",
				alt: "Play video",
				url: "https://www.youtube.com/watch?v=gIz43yaA-Bo",
				target: "_blank",
			},
			tag: "2020-07-10",
			title: "Partnership in the after-school space",
			description: "One of our partners, IkamvaYouth, looks at the importance of partnerships in facilitating after-school programmes. ",
			link: {
				modifier: "",
				target: "_blank",
				text: "Play video",
				url: "https://www.youtube.com/watch?v=gIz43yaA-Bo",
			},
		},
		{
			image: {
				type: "",
				src: "/assets/images/publications/article-malnutrition.jpg",
				alt: "Malnutrition: ‘A slow violence against our children",
			},
			tag: "2021-22-02",
			title: "Malnutrition: ‘A slow violence against our children",
			description: "Dr Tshepo Motsepe’s keynote address at the launch of the South African Child Gauge 2020 focussed on food safety.",
			link: {
				modifier: "",
				target: "_blank",
				text: "Read more",
				url: "https://www.news.uct.ac.za/article/-2021-02-22-malnutrition-a-slow-violence-against-our-children",
			},
		},
		{
			image: {
				type: "",
				src: "/assets/images/publications/article-matric-results-unequal.jpg",
				alt: "Matric results highlight unequal schooling in SA – and the dedication of our teaching corps",
			},
			tag: "2021-28-02",
			title: "Matric results highlight unequal schooling in SA – and the dedication of our teaching corps",
			description: "An opinion piece by Prof. Michael le Cordeur, head of Stellenbosch University’s Department of Curriculum Studies and ambassador of the Rapport Bursary Fund.",
			link: {
				modifier: "",
				target: "_blank",
				text: "Read more",
				url: "https://www.dailymaverick.co.za/opinionista/2021-02-28-matric-results-highlight-unequal-schooling-in-south-africa-and-the-dedication-of-our-teaching-corps/",
			},
		},
	]
};

export { publicationsData };
